import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const helpAPi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        help: builder.mutation({
            query: (body) => ({
                url: END_POINTS.help,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useHelpMutation,
} = helpAPi;
