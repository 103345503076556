const Checkbox = ({ onChange, checked }) => {
  return (
    <div className="input_checkbox">
      <input
        type="checkbox"
        id="remember1"
        name="remember1"
        onChange={onChange}
        checked={checked} />
      <label
        htmlFor="remember1">
        Remember Me
      </label>
    </div>
  );
};
export default Checkbox;
