/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { useLazyAgntDashboardQuery } from "../../../services/agentdashboard";
import useAuth from "../../../hooks/useAuth";

const WelcomeBox = () => {
  const [totalChat, setTotalChat] = useState();
  const [cmltChat, setCmltChat] = useState();
  const [activeChat, setActiveChat] = useState();
  const [load, setLoad] = useState(false);
  const [pendingChat, setPendingChat] = useState();
  const [cardData] = useLazyAgntDashboardQuery();
  const userData = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        const res = await cardData();
        const response = res?.data?.data;
        if (response) {
          console.log("response::::::::>", response);
          setCmltChat(response?.complete_chat);
          setTotalChat(response?.total_chat);
          setActiveChat(response?.active_chat);
          setPendingChat(response.pending_chat);
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, [cardData]);

  return (
    <div className="welcomeBox_card shd_bx">
      <div className="welcome_head">
        <figure>
          <img
            src={
              userData?.profile_picture?.media_file_url ?? "/images/frame.png"
            }
            alt=""
            loading={load}
          />
        </figure>
        <div>
          <h2>Welcome {userData?.first_name + " " + userData?.last_name}</h2>
          <p>We are glad to have you today !</p>
        </div>
      </div>
      <ul>
        <li className="activeLi">
          <p>Total Chat Resolved</p>
          <h3>{totalChat ?? "0"}</h3>
        </li>
        <li>
          <h3>{activeChat ?? "0"}</h3>
          <p>Active Chat</p>
        </li>
        <li>
          <h3>{pendingChat ?? "0"}</h3>
          <p>Pending Chat</p>
        </li>
        <li>
          <h3>{cmltChat ?? "0"}</h3>
          <p>Resolve Chat</p>
        </li>
      </ul>
    </div>
  );
};
export default WelcomeBox;
