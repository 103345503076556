/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useBotTmpltMutation } from "../../../services/createbot";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setStepperData } from "../../../reducers/featureSlice";

const TemplateBot = () => {
  const [botTemplate] = useBotTmpltMutation();
  const [load, setLoad] = useState(false);
  const [botTemData, setBotTemData] = useState();
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        let body = {
          page: 1,
          length: 20,
          search_value: "",
        };
        const response = await botTemplate(body);
        setBotTemData(response?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleTemp = (id) => {
    history("/botprofile", { state: { id: id } });
    dispatch(setStepperData({ stepperData: "template" }));
  };

  return (
    <>
      <Spin spinning={load} size="medium">
        <div className="d_flx template_flx mt_5">
          {botTemData?.length !== 0 ? (
            botTemData?.map((botTemp) => (
              <div
                onClick={() => handleTemp(botTemp?.id)}
                className="template_box"
                key={botTemp?.id}
              >
                {botTemp?.bot_service_image ? (
                  <figure>
                    <img
                      src={botTemp?.bot_service_image?.media_file_url}
                      alt="bot template"
                    />
                  </figure>
                ) : (
                  <i className="fa-solid fa-question" />
                )}
                <h2>{botTemp?.title}</h2>
                <p>{botTemp?.description}</p>
              </div>
            ))
          ) : (
            <div className="template_box">
              <i className="fa-solid fa-question" />
              <h2> Bot template not available</h2>
            </div>
          )}
        </div>
      </Spin>
    </>
  );
};
export default TemplateBot;
