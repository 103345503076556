import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const ratingByCust = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        ratingByCust: builder.mutation({
            query: (body) => ({
                url: END_POINTS.ratingByCust,
                method: 'POST',
                body,
            }),
        }),
        filterRating: builder.mutation({
            query: (body) => ({
                url: END_POINTS.filterRating,
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const
    {
        useRatingByCustMutation,
        useFilterRatingMutation,
    } = ratingByCust;
