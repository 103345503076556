import { Route, Routes } from "react-router-dom";
import Pages from "./pages";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import SignUp from "./pages/signup";
import Dashboard from "./pages/dashboard";
import EditProfile from "./pages/editprofile";
import Conversation from "./pages/conversation";
import BotIntegration from "./pages/botintegration";
import BotTemplate from "./pages/bottemplate";
import BotProfile from "./pages/botprofile";
import Handoff from "./pages/handoff";
import SuccessIntegration from "./pages/successintegration";
import ManageBot from "./pages/managebot";
import Customer from "./pages/customer";
import Employees from "./pages/employees";
import AddAgent from "./pages/addagent";
import AgentView from "./pages/agentview";
import DefaultFlow from "./pages/defaultflow";
import DomainSetup from "./pages/domainsetup";
import BusinessTraining from "./pages/businesstraining";
import Rating from "./pages/rating";
import Thememanagement from "./pages/thememanagement";
import ApiKey from "./pages/apikey";
import Subscription from "./pages/subscription";
import Servey from "./pages/servey";
import AgentAvailability from "./pages/agentavailability";
import FallbackEmail from "./pages/fallbackemail";
import PushNotification from "./pages/pushnotification";
import CreateBot from "./pages/creatbot";
import AgentDashboard from "./pages/agent-dashboard";
import AgentConversation from "./pages/agent-conversation";
import Contact from "./pages/contact";
import ServerNotFound from "./pages/server";
import BotBuilder from "./pages/botbuilder";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Pages.HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/conversation" element={<Conversation />} />
        <Route path="/botintegration" element={<BotIntegration />} />
        <Route path="/bot_template" element={<BotTemplate />} />
        <Route path="/botprofile" element={<BotProfile />} />
        <Route path="/handoff" element={<Handoff />} />
        <Route path="/success" element={<SuccessIntegration />} />
        <Route path="/managebot" element={<ManageBot />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/addagent" element={<AddAgent />} />
        <Route path="/agentview" element={<AgentView />} />
        <Route path="/defaultflow/:id" element={<DefaultFlow />} />
        <Route path="/domainsetup" element={<DomainSetup />} />
        <Route path="/training" element={<BusinessTraining />} />
        <Route path="/rating" element={<Rating />} />
        <Route path="/thememanagement" element={<Thememanagement />} />
        <Route path="/apikey" element={<ApiKey />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/servey" element={<Servey />} />
        <Route path="/agentavailability" element={<AgentAvailability />} />
        <Route path="/fallbackemail" element={<FallbackEmail />} />
        <Route path="/pushnotification" element={<PushNotification />} />
        <Route path="/createbot" element={<CreateBot />} />
        <Route path="/agent-dashboard" element={<AgentDashboard />} />
        <Route path="/agent-conversation" element={<AgentConversation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/server-not-found" element={<ServerNotFound />} />
        <Route path="/botbuilder" element={<BotBuilder />} />
      </Routes>
    </>
  );
};

export default Router;
