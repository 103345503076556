// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "chat-bot-efac0.firebaseapp.com",
  projectId: "chat-bot-efac0",
  storageBucket: "chat-bot-efac0.appspot.com",
  messagingSenderId: "1029332874114",
  appId: "1:1029332874114:web:4a8a8128bf6e645381959b",
  measurementId: "G-V1TB7SKK92",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
