/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import SettingSidebar from "../../components/settingsidebar";
import CstaRating from "./feature/cstarating";

const Servey = () => {
  return (
    <>
      <Layout>
        <section className="setting_page d_flx align_top">
          <SettingSidebar />
          <CstaRating />
        </section>
      </Layout>
    </>
  );
};
export default Servey;
