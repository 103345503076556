import { Dropdown, Empty } from "antd";
import React, { useState } from "react";
import { timesAgo } from "../utils/timeago";
import { useHeaderNotificationMutation } from "../services/dashboard";

const Notification = () => {
  const [hdrNotifcn] = useHeaderNotificationMutation();
  const [notifctData, setnotifctData] = useState([]);

  const items = [
    {
      label: (
        <div className="drop_down">
          <ul className="noti_fication">
            {notifctData?.length ? (
              notifctData?.map((data) => (
                <li className="d_flx" key={data?.id}>
                  <div className="noti_cnt">
                    <figure>
                      <img src="/images/noti.svg" alt="notification"></img>
                    </figure>
                    <p>{data?.title}</p>
                    <p>{data?.message}</p>
                  </div>
                  <span>{timesAgo(data?.created_at)}</span>
                </li>
              ))
            ) : (
              <Empty />
            )}
          </ul>
        </div>
      ),
      key: "0",
    },
  ];

  const notification = async () => {
    let res;
    try {
      res = await hdrNotifcn();
      console.log("res: ", res);
      setnotifctData(res?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
      <figure onClick={notification}>
        <img src="/images/notification-bing.svg" alt="notification"></img>
      </figure>
    </Dropdown>
  );
};

export default Notification;
