import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const agntDbrd = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        agntDashboard: builder.query({
            query: () => ({
                url: END_POINTS.agntDashboard,
                method: 'GET',

            }),
        }),
        pieData: builder.query({
            query: () => ({
                url: END_POINTS.pieData,
                method: 'GET',

            }),
        }),
        convsnHist: builder.mutation({
            query: (body) => ({
                url: END_POINTS.convsnHistory,
                method: 'POST',
                body,
            }),
        }),
        agentStatus: builder.query({
            query: () => ({
                url: END_POINTS.agentStatus,
                method: 'GET',
            })
        }),
        putAgentStatus: builder.mutation({
            query: (body) => ({
                url: END_POINTS.putAgentStatus,
                method: 'PUT',
                body,
            })
        }),
    }),
})

export const
    {
        useLazyAgntDashboardQuery,
        useLazyPieDataQuery,
        useConvsnHistMutation,
        useLazyAgentStatusQuery,
        usePutAgentStatusMutation,
    } = agntDbrd;
