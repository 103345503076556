import React from "react";

const Seamless = () => {
  return (
    <section className="al_sc" data-aos="zoom-out-up">
      <div className="conta_iner">
        <div className="bg_light">
          <div className="text_center max_60">
            <h2 className="wt_reg">
              Seamless
              <span className="clr_theme">
                {" "}
                One -click <img src="/images/cursor.svg" alt="cursor"></img>
              </span>{" "}
              integrations
            </h2>
            <p className="gry_txt">
              No-code template integrations for business users and
              hyper-flexible APIs for developers.
            </p>
          </div>
          <ul className="box_wht d_flx">
            <li>
              <figure>
                <img src="/images/icn1.svg" alt="icon"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/icn2.svg" alt="icon"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/icn3.svg" alt="icon"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/icn4.svg" alt="icon"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/icn5.svg" alt="icon"></img>
              </figure>
            </li>
            <li>
              <figure>
                <img src="/images/icn6.svg" alt="icon"></img>
              </figure>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Seamless;
