import React from "react";

const Video = () => {
  return (
    <div className="conta_iner">
      <div className="d_flx al_sc mtb_60">
        <div className="abt_lhs w_48 bg_lt">
          <figure>
            <img src="/images/video.png" alt="hero"></img>
          </figure>
        </div>
        <div className="abt_lhs w_48" data-aos="fade-in-left">
          <h2>
            The AI everyone is
            <br />
            chatting a (bot)
          </h2>
          <p className="gry_txt">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in
            iaculis ligula. Donec mollis, quam vel condimentum dignissim, eros
            erat ultrices tellus, eu porttitor lacus turpis eget ante. Praesent
            quis pharetra metus, ac sollicitudin urna. Mauris nisl nisi,
            vestibulum eleifend quam vitae, ullamcorper ullamcorper enim.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Video;
