import { useState } from "react";
import {
  isPasswordPattern,
  matchPassword
} from "../utils/validations";
import {
  EyeFilled,
  EyeInvisibleFilled
} from '@ant-design/icons';

const ChangePass = ({ placeholder, value, onChange, require, newPass, val, onKeyDown }) => {
  const [showPass, setShowPass] = useState('password');
  const showError = (errorMessage) => {
    return <p style={{ color: 'red', fontSize: 15 }}>{errorMessage}</p>;
  };

  const validateInput = () => {
    if (require) {
      if (!value) {
        return showError(`Please ${placeholder}`);
      } else if (val === 2 && !isPasswordPattern(value)) {
        return showError("Please enter valid password");
      } else if (val === 3 && !matchPassword(newPass, value)) {
        return showError("Password didn't match");
      }
    }
    return null;
  };

  const pass = () => {
    if (showPass === 'password') {
      setShowPass('text')
    } else {
      setShowPass('password')
    }
  }

  return (
    <div className="input_box pd_input">
      <input
        type={showPass}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown} />
      <figure className="edit_eye" onClick={pass}>
        {showPass === 'password'
          && (<EyeInvisibleFilled
            style={{
              fontSize: '22px',
              color: '#9aa4b2'
            }} />)}
        {showPass === 'text'
          && (<EyeFilled
            style={{
              fontSize: '22px',
              color: '#9aa4b2'
            }} />)}
      </figure>
      {validateInput()}
    </div>
  );
};
export default ChangePass;
