/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import CustomerList from "./features/employeelist";

const Employees = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          <CustomerList />
        </section>
      </Layout>
    </>
  );
};
export default Employees;
