/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import Add from "./features/add";

const AddAgent = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
            <Add />
        </section>
      </Layout>
    </>
  );
};
export default AddAgent;
