/* eslint-disable jsx-a11y/img-redundant-alt */
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { SlScreenDesktop } from "react-icons/sl";

const WidgetPosition = ({ setOpen, open, value, onChange }) => {
  const [widPos, setWidPos] = useState();

  const style = {
    color: "#fff",
    cursor: "pointer",
    marginRight: "8px",
    padding: 4,
    background: "#414de1",
    borderRadius: "6px",
  };

  const defaultStyle = {
    color: "rgb(0 0 0)",
    padding: 4,
    backgroundColor: "#fff",
    cursor: "pointer",
  };

  function closeModal() {
    setOpen(false);
  }

  const handleLiClick = (index) => {
    console.log("index: ", index);
    setWidPos(index);
  };

  useEffect(() => {
    if (value !== undefined) {
      setWidPos(value);
    }
  }, [value]);

  function handleOk() {
    setOpen(false);
    onChange(widPos);
  }

  return (
    <div>
      <Modal open={open} onCancel={closeModal} onOk={handleOk}>
        <SlScreenDesktop className="screen" />
        <div className="position_main">
          <div className="posi_tion">
            <p
              style={widPos === 5 ? style : defaultStyle}
              onClick={() => handleLiClick(5)}
            >
              Top left
            </p>
            <p
              style={widPos === 2 ? style : defaultStyle}
              onClick={() => handleLiClick(2)}
            >
              Top right
            </p>
          </div>
          <div className="posi_tion">
            <p
              style={widPos === 6 ? style : defaultStyle}
              onClick={() => handleLiClick(6)}
            >
              Middle left
            </p>
            <p
              style={widPos === 3 ? style : defaultStyle}
              onClick={() => handleLiClick(3)}
            >
              Middle right
            </p>
          </div>
          <div className="posi_tion">
            <p
              style={widPos === 4 ? style : defaultStyle}
              onClick={() => handleLiClick(4)}
            >
              Botton left
            </p>
            <p
              style={widPos === 1 ? style : defaultStyle}
              onClick={() => handleLiClick(1)}
            >
              Bottom right
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default WidgetPosition;
