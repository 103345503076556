import { successToast } from "../../../helpers/showToast";
import PasswordCnf from "../../../components/passwordCnf";
import Password from "../../../components/password";
import FormHead from "../../../components/formhead";
import LoginButton from "../../../components/loginbutton";
import { useNewPasswordMutation } from "../../../services/api";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isPasswordPattern, matchPassword } from "../../../utils/validations";
import { Spin } from "antd";

const ResetForm = () => {
  let history = useNavigate();
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newPassward] = useNewPasswordMutation();
  const location = useLocation();
  const { state } = location;
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false);

  const LoginPage = async () => {
    if (
      !pass ||
      !confirmPass ||
      !matchPassword(pass, confirmPass) ||
      !isPasswordPattern(pass)
    ) {
      setRequire(true);
      console.log("please enter fields");
      return;
    }
    let body = {
      email: state?.key,
      new_password: confirmPass,
    };
    try {
      setLoad(true);
      const response = await newPassward(body);
      console.log("response.data.code: ", response.data.code);
      successToast(response.data.message);
      if (response.data.code === 201) {
        history("/login");
      } else {
        console.log("Please enter correct credentials");
      }
    } catch (error) {
      console.log("enter correct credentials");
      console.error("Error:", error);
    } finally {
      setLoad(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      LoginPage();
    }
  };
  return (
    <>
      <Spin spinning={load}>
        <section className="login_page">
          <div className="login_bx">
            <div className="lg_frm">
              <FormHead title="Reset your password" />
              <Password
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <PasswordCnf
                placeholder="Confirm Password"
                value={confirmPass}
                cfmPass={pass}
                onChange={(e) => setConfirmPass(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <LoginButton name="Reset Password" click={LoginPage} />
            </div>
          </div>
        </section>
      </Spin>
    </>
  );
};

export default ResetForm;
