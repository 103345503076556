/* eslint-disable jsx-a11y/img-redundant-alt */
import InputBox from "../../../components/inputbox";
import SaveBtn from "../../../components/savebtn";
import React, { useEffect, useState } from "react";
import {
  useAddServerKeyMutation,
  useLazyGetServerKeyQuery,
} from "../../../services/pushnotication";
import { successToast } from "../../../helpers";

const Notification = () => {
  const [addServerKey] = useAddServerKeyMutation();
  const [getServerKey] = useLazyGetServerKeyQuery();
  const [serverKey, setServerKey] = useState("");
  const [load, setLoad] = useState(false);

  const saveServerKey = async () => {
    try {
      setLoad(true);
      let body = {
        fcm_server_key: serverKey,
      };
      let response = await addServerKey(body);
      successToast(response?.data?.message);
    } catch (err) {
      console.log(err);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    const fetchServerKey = async () => {
      try {
        let response = await getServerKey();
        setServerKey(response?.data?.data?.fcm_server_key);
      } catch (err) {
        console.log(err);
      }
    };
    fetchServerKey();
  }, [getServerKey]);

  return (
    <>
      <div className="stng_rhs">
        <div className="agent_head">
          <h2>Push Notifications</h2>
          <p>
            Enabling push notification allows Kommunicate to send notifications
            even when your mobile app is not in the foreground.
          </p>
          <hr />
        </div>
        <div className="noti_intig_bx">
          {/* <h3>For Notification</h3> */}
          <div className="clr_label">
            <label>Server key :</label>
            <InputBox
              type="text"
              value={serverKey}
              onChange={(e) => setServerKey(e.target.value)}
            />
          </div>
          <SaveBtn title="Save" onClick={saveServerKey} load={load} />
        </div>
        <hr />
      </div>
    </>
  );
};
export default Notification;
