import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";


const FlowDesigner = () => {
  let history = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);

  function Classic() {
    history("/botbuilder");
  }

  function DefaultFlow(){
    history('/defaultflow')
  }

  function IntentPage(){
    history('/defaultflow/intentView')
  }

  function WcMessage(){
    history('/defaultflow/buildView')
  }
  function SmallTalk(){
    history('/defaultflow/smallTalk')
  }

  const handleTabSelection = (index) => {
    setTabIndex(index)
    switch(index){
      case 0:
        history(`/defaultflow/flowStructure`);
        break;
      case 1:
        history(`/defaultflow/buildView`);
        break;
      default:
        history(`/defaultflow/flowStructure`);
        break;
    }
  }
  
  return (
    <>
      <div className="flow_sidebar tab_nwbx">
        
        <Tabs
        onSelect={(index) => handleTabSelection(index)}
        selectedIndex={tabIndex}
        >
          <TabList>
            <Tab >Flow Designer</Tab>
            <Tab >Classic</Tab>
          </TabList>
          <TabPanel>
            <ul>
              <li>
                <Link to="/defaultflow">FAQ</Link>
              </li>
              {/* <li className={window.location.pathname === "/domainsetup" ? "active" : ""}>
            <Link to="/domainsetup">Domain Setup</Link>
        </li>
        <li  className={window.location.pathname === "/training" ? "active" : ""}>
            <Link to="/training">Business Training</Link>
        </li> */}
            </ul>
          </TabPanel>
          <TabPanel>
            <div className="classic_sidebar">
              <div className="cls_srch"></div>
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading onClick={WcMessage}>
                    <AccordionItemButton>
                      <figure>
                        <img src="../images/wlcm.svg" alt="welcome" />
                      </figure>
                      <div>
                        <h3>Welcome Message</h3>
                        <p className="hide_cnt">
                          Set an initial message for users
                        </p>
                      </div>
                      <figure className="arw_dwn">
                        <img src="../images/down_arrow.svg" alt="welcome" />
                      </figure>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Setup a welcome message for your users</p>
                    <button type="button" className="add_btn">
                      + Add
                    </button>
                    <button type="button" className="intent_btn">
                      Default Welcome Intent
                    </button>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading onClick={IntentPage}>
                    <AccordionItemButton>
                      <figure>
                        <img src="../images/intents.svg" alt="" />
                      </figure>
                      <div>
                        <h3>Intents</h3>
                        <p className="hide_cnt">
                          Setup training phrases and bot responses
                        </p>
                      </div>
                      <figure className="arw_dwn">
                        <img src="../images/down_arrow.svg" alt="welcome" />
                      </figure>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Setup training phrases and bot responses</p>
                    <button type="button" className="add_btn">
                      + Add
                    </button>
                    <button type="button" className="intent_btn">
                      New Intents
                    </button>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading onClick={SmallTalk}>
                    <AccordionItemButton>
                      <figure>
                        <img src="../images/talk.svg" alt="" />
                      </figure>
                      <div>
                        <h3>Small Talk</h3>
                        <p className="hide_cnt">
                          Setup answers for generic replies
                        </p>
                      </div>
                      <figure className="arw_dwn">
                        <img src="../images/down_arrow.svg" alt="welcome" />
                      </figure>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Set up small talk for generic messages such as Hello, Hi,
                      Good Morning, etc.
                    </p>
                    <button type="button" className="add_btn">
                      + Add
                    </button>
                    <button type="button" className="intent_btn">
                      New Small Talk
                    </button>
                  </AccordionItemPanel>
                </AccordionItem>

                {/* <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <figure>
                        <img src="../images/doc.svg" alt="" />
                      </figure>
                      <div>
                        <h3>Documents</h3>
                        <p className="hide_cnt">
                          Use GenAI to train on documents
                        </p>
                      </div>
                      <figure className="arw_dwn">
                        <img src="../images/down_arrow.svg" alt="welcome" />
                      </figure>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Train your Bot to answer from uploaded documents</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <figure>
                        <img src="../images/web.svg" alt="" />
                      </figure>
                      <div>
                        <h3>Website Scraper</h3>
                        <p className="hide_cnt">
                          Use GenAI to train on documents
                        </p>
                      </div>
                      <figure className="arw_dwn">
                        <img src="../images/down_arrow.svg" alt="welcome" />
                      </figure>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Train your Bot to answer from your website</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <figure>
                        <img src="../images/dflt.svg" alt="" />
                      </figure>
                      <div>
                        <h3>Default Fallback</h3>
                        <p className="hide_cnt">
                          Fallback when bot fails to answer
                        </p>
                      </div>
                      <figure className="arw_dwn">
                        <img src="../images/down_arrow.svg" alt="welcome" />
                      </figure>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Setup fallback message when bot is not able to answer</p>
                    <button type="button" className="intent_btn">
                      Setup Default Fallback
                    </button>
                  </AccordionItemPanel>
                </AccordionItem> */}
              </Accordion>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};
export default FlowDesigner;
