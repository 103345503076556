import React from "react";
import Layout from "../../layout";
import ResetForm from "./features/resetform";

function ResetPassword() {
  return (
    <Layout>
        <ResetForm />
    </Layout>
  );
}

export default ResetPassword;
