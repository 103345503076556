export const API_URL = "https://dev-ai-chatbot.webdevelopmentsolution.net/";
// Staging
// export const API_URL = "https://ai-chatbot.webdevelopmentsolution.net/";

export const END_POINTS = {
  signUp: "authentication/auth/signup/",
  signUpOtp: "authentication/signup-verification/otp/",
  logIn: "authentication/auth/login/",
  socialLogin: "authentication/social-login/",
  logout: "authentication/auth/logout",
  userFgtPass: "authentication/user/forgot-password-email/",
  userFgtPassOtp: "authentication/forgot-password-verification/otp/",
  newPassward: "authentication/otp/reset-password/",
  uploadMedia: "authentication/upload/media/",
  changePass: "authentication/user/change-password/",
  getUserDetails: "authentication/user-detail-by-token",
  editProgile: "super-admin/edit-profile/",
  // dashboard
  getCardData: "authentication/client/dashboard-data/",
  getNewAgent: "authentication/client/get-new-agent/",
  activeChat: "authentication/client/dashboard-active-chat-data/",
  headerNotification: "push-notification/client/notification/",
  turnAwayMode: "agent/user/agent-offline/",
  agentStatus: "agent/user/agent-online/",
  putAgentStatus: "agent/user/agent-offline/",
  getTestMode: "bot/user/get-test-mode-status/",
  PutTestMode: "bot/user/update-test-mode/",
  satisfiedRating: "authentication/client/rating-avg-by-company-id/",
  avgTalkToHuman: "authentication/client/graph-avg-talk-to-human/",
  avgResponseTime: "authentication/client/agent-average-response/",
  // #Agent
  addAgent: "agent/user/add-agent/",
  companyAgent: "agent/user/get-agent-by-company/",
  deleteAgent: "agent/user/delete-agent-by-id/",
  editAgent: "agent/user/update-agent-by-id/",
  AgentById: "agent/user/get-agent-by-id/",
  filterAgent: "agent/user/get-filter-agent-by-company/",
  pieDataById: "agent/agent-pie-data-by-id/",
  convsnHistoryById: "agent/agent-all-conversation-listing-by-id/",

  // #create bot
  getBotTemplate: "bot/get-all-template/",
  createBot: "bot/user/add-chatbot/",
  getAllAvatar: "bot/user/get-all-avatar/",
  addAvatar: "bot/user/add-avatar/",
  botList: "bot/user/get-chatbot-by-company/",
  botHandleConvsn: "bot/user/update-chatbot-by-id/",
  // manage bot
  defaultFlow: "/bot/default-flow/",
  addMessage: "/bot/options/create/",
  deleteOption: "/bot/options-delete/",
  updateOpt: "/bot/options-update/",
  OptionNoParent: "/bot/options/no-parent/",
  optionById: "bot/options-by-parent-id/",
  getDomainById: "bot/get-domain-setup-by-id/",
  // #setting
  subscriptionList: "authentication/get-all-subscription/",
  buySubscription: "authentication/buy-client-subscription/",
  themeById: "/bot/get-theme-by-company-id/",
  getAgntTiming: "agent/get-agent-shift-timings/",
  agentTiming: "agent/update-agent-shift-timings/",
  pushNotification: "push-notification/client/fcm-tester/",
  addServerKey: "push-notification/client/add/server/key",
  getServerKey: "push-notification/client/get/server/key",
  editThemeById: "/bot/update-theme-by-company-id/",
  updateCsat: "bot/update-csat-by-company-id/",
  updateFallback: "bot/update-fallback-email-by-company-id/",
  SendAwaymessage: "bot/update-show-away-message-by-company-id/",
  anonymousUser: "bot/update-away-message-anonymous-by-company-id/",
  // #manage rating
  ratingByCust: "customer/get/rating/customer/",
  filterRating: "customer/get/filter/rating/",
  // #manage customer
  customerList: "customer/get/all/customer/",
  filterCust: "customer/get/filter/customer/",
  deleteCust: "customer/delete/customer/",
  blockCust: "customer/block/customer/",
  getBlkCust: "customer/get/customer-by-id/",

  // #Conversation
  getagentList: "agent/agent-listing-company/",
  queued: "agent/queued-session-chats/",
  asignedChat: "agent/assigned-session-chats/",
  resolvedChat: "agent/resolved-session-chats/",
  allCustChat: "agent/all-customer-chats/",
  reAssignList: "/agent/reassign-session-chats/",
  reAssignAgent: "agent/reassign-agent-to-customer-converstion/",
  // #Agent Conversation
  allConvrstn: "agent/user/all-conversation/",
  queueChat: "agent/queued-session-chats/",
  previousChat: "agent/display-previous-chats/",
  assignToAgnt: "/agent/assign-agent-to-customer-converstion/",
  filterCnvsCust: "agent/customer-filter-listing-company/",
  markAsUnresolved: "agent/update/mark-as-unresolved-by-session-id/",
  // Agent Dashboard
  agntDashboard: "agent/agent-dashboard-data/",
  pieData: "agent/agent-pie-data/",
  convsnHistory: "agent/agent-all-conversation-listing/",
  // Agent conversation
  agntAssign: "agent/all-customer-chats/",
  agntResolve: "agent/agent-resolved-listing/",
  allChats: "agent/all-listing-customer-chats/",
  customerFilter: "/agent/customer-filter-agent/",
  // help
  help: "authentication/create-queries/",
};
