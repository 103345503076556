import React, {
    useState
} from "react";
import Layout from "../../layout";
import ConversationLeftPanel from "./features/left-panel";
import ConversationPanel from "./features/conversation-panel";

const Conversation = () => {

    const [idFromLeftPanel, setIdFromLeftPanel] = useState(null);
    const [nameFromLeftPanel, setnameFromLeftPanel] = useState(null);
    const [tabSlt, setTabSlt] = useState(null);
    const [AssignResponse, setAssignResponse] = useState(null);

    const receiveIdFromLeftPanel = (id) => {
        setIdFromLeftPanel(id);
    };

    const receiveNameFromLeftPanel = (name) => {
        setnameFromLeftPanel(name);
    };

    const receiveSelectedTab = (tab) => {
        setTabSlt(tab);
    }

    const receiveAssingResponse = (key) => {
        setAssignResponse(key)
    }

    return (
        <>
            <Layout>
                <section className="main_area">
                    <div className="conv_wrap">
                        <ConversationLeftPanel
                            sendIdToParent={receiveIdFromLeftPanel}
                            sendNameToParent={receiveNameFromLeftPanel}
                            sendSelectedTab={receiveSelectedTab}
                            AssignResponse={AssignResponse} />
                        <ConversationPanel
                            idFromLeftPanel={idFromLeftPanel}
                            nameFromLeftPanel={nameFromLeftPanel}
                            tabSlt={tabSlt}
                            sendAssignResponse={receiveAssingResponse} />
                    </div>
                </section>
            </Layout>
        </>
    );
};
export default Conversation;
