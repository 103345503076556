import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import {
  useLazyOptionByIdQuery,
  useLazyOptionNoParentQuery,
} from "../services/managebot";
import { useLazyThemeByIdQuery } from "../services/settings";
import { Spin } from "antd";
import useAuth from "../hooks/useAuth";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
  },
};

const BotChat = ({ setOpen, open, id, botImgUrl, botName }) => {
  const [optionsNoParent] = useLazyOptionNoParentQuery();
  const [themeManage] = useLazyThemeByIdQuery();
  const [optionById] = useLazyOptionByIdQuery();
  const [botChat, setBotChat] = useState([]);
  const [olnStatus, setOlnStatus] = useState("We reply immediately");
  const [color, setColor] = useState();
  const [load, setLoad] = useState(false);
  const [inputText, setInputText] = useState();
  const userData = useAuth();
  const chatContainerRef = useRef(null);

  function closeModal() {
    setOpen(false);
    setBotChat([]);
  }

  const handleGetOptById = async (optId, name) => {
    try {
      const response = await optionById(optId);
      console.log("response: ", response);
      if (name) {
        setBotChat((prevBotChat) => [
          ...prevBotChat,
          { id: null, name, type: "user" },
        ]);
      }
      if (response?.data?.data?.length) {
        setBotChat((prevBotChat) => [...prevBotChat, ...response.data.data]);
      } else {
        setBotChat((prevBotChat) => [
          ...prevBotChat,
          { id: null, name: "Talk to human" },
        ]);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await optionsNoParent(id);
        console.log("response: ", response);
        setBotChat(response?.data?.data);
      } catch (error) {
        console.log("error: ", error);
      }
    };

    const fetchThemeData = async () => {
      try {
        setLoad(true);
        const response = await themeManage(
          userData?.business_name?.bussiness_id
        );
        const responseData = response?.data?.data;
        console.log("response: ", response);
        setColor(responseData?.back_ground_color);
        setOlnStatus(responseData?.online_status);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    if (id) {
      fetchData();
    }
    fetchThemeData();
  }, [optionsNoParent, id, themeManage, open]);

  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [botChat]);

  const sendMessage = async () => {
    if (inputText) {
      setBotChat((prevBotChat) => [
        ...prevBotChat,
        { id: null, name: inputText, type: "user" },
      ]);

      setTimeout(() => {
        setBotChat((prevBotChat) => [
          ...prevBotChat,
          {
            id: null,
            name: "I have no idea how to respond to this. I'll put you in touch with a real agent to assist you with your inquiry.",
            type: "bot",
          },
        ]);
      }, 2000);
    }
    setInputText("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={open}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="custmoize_theme theme_modal chat_modal">
          <div className="theme_rhs">
            <div className="chat_design">
              <div className="sara_head" style={{ background: color }}>
                <figure>
                  <img src={botImgUrl ?? "/images/bot.png"} alt="img"></img>
                </figure>
                <div>
                  <p>Chat with</p>
                  <h3>{botName}</h3>
                </div>
              </div>
              <div className="reply_tetx" style={{ background: color }}>
                <i className="fa-solid fa-circle"></i>{" "}
                <p>{olnStatus ?? "We reply immediately"}</p>
              </div>
              <div className="test_chat_box" ref={chatContainerRef}>
                <Spin spinning={load} size="large">
                  {botChat?.map((item, index) =>
                    item?.type === "user" ? (
                      <div
                        className="chat_right"
                        key={index}
                        style={{ background: color }}
                      >
                        <p>{item?.name}</p>
                      </div>
                    ) : (
                      <>
                        <div className="chat_left" key={index}>
                          <p
                            style={item?.id ? { cursor: "pointer" } : null}
                            onClick={() =>
                              item?.id
                                ? handleGetOptById(item?.id, item?.name)
                                : null
                            }
                          >
                            {item?.name}
                          </p>
                        </div>
                        {/* {item?.name === "Talk to human" && (
                          <>
                            <div className="chat_ended">
                              <p>Chat Ended</p>
                            </div>
                            <div className="star_c">
                              <i className="fa-regular fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                              <i className="fa-regular fa-star"></i>
                            </div>
                          </>
                        )} */}
                      </>
                    )
                  )}
                </Spin>
              </div>

              <div className="chat_input">
                <input
                  type="text"
                  placeholder="Enter your message....."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <figure style={{ background: color }}>
                  <img
                    src="./images/send.svg"
                    alt="send"
                    onClick={sendMessage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BotChat;
