import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { getData } from "../../constants";
import { STORAGE_KEYS } from "../../constants/storage";

const Header = () => {
  const userData = useAuth();
  const history = useNavigate();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  const renderLogin = () => {
    const token = getData(STORAGE_KEYS.token);
    if (token) {
      if (userData?.role === 2) {
        history("/agent-dashboard");
      } else if (userData?.role === 1) {
        history("/dashboard");
      }
    } else {
      history("/login");
    }
  };

  return (
    <>
      <header className={scroll ? "scrolled" : ""}>
        <div className="conta_iner">
          <nav>
            <Link to="/">
              <figure>
                <img src="./images/logo.svg" alt="logo" />
              </figure>
            </Link>
            <button className="btn_primary" onClick={renderLogin}>
              Start Free Trial
            </button>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
