import { useEffect, useState } from "react";
import { isEmail } from "../utils/validations";

const Email = ({
  placeholder,
  value,
  onChange,
  require,
  onKeyDown,
  login,
  disabled,
}) => {
  const [placeValue, setPlaceValue] = useState("");

  useEffect(() => {
    if (placeholder) {
      setPlaceValue(placeholder);
    } else setPlaceValue("Email");
  }, [placeholder]);

  const showError = (errorMessage) => {
    return <p style={{ color: "red", fontSize: 15 }}>{errorMessage}</p>;
  };

  const validateInput = () => {
    if (require) {
      if (!value) {
        return showError("Please enter email");
      } else if (!login && !isEmail(value)) {
        return showError("Please enter valid email");
      }
    }
    if (value && !isEmail(value)) {
      return showError("Enter valid email id");
    }
    return null;
  };
  return (
    <div className="input_box">
      <figure className="sms">
        <img src="/images/sms.svg" alt="customer"></img>
      </figure>
      <input
        type="email"
        placeholder={placeValue}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      {validateInput()}
    </div>
  );
};
export default Email;
