import SearchBox from "../../../components/search";
import Filter from "../../../components/filter";
import PaginationBox from "../../../components/pagination";
import timesAgo from "../../../utils/timeago";
import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Empty, Skeleton } from "antd";
import {
  useCustomerListMutation,
  useFilterCustMutation,
} from "../../../services/manageCust";
import CustomerDelBolc from "../../../components/CustomerDelBolc";

const CustomerList = () => {
  const [companyAgent] = useCustomerListMutation();
  const [filterCust] = useFilterCustMutation();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [totalCnt, setTotalCnt] = useState();
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await companyAgent(body);
      setData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [current, companyAgent]);

  useEffect(() => {
    if (!from || !to) {
      fetchData();
    }
  }, [fetchData, from, to]);

  const filter = async () => {
    try {
      setLoad(true);
      let body = {
        start_date: from,
        end_date: to,
      };
      if (from && to) {
        const response = await filterCust(body);
        setData(response?.data?.data);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const capitalizeFirstLetter = (word) => {
    if (word.length === 0) {
      return "";
    }
    return word[0].toUpperCase();
  };

  const handleFromChange = (e) => {
    setFrom(e);
  };

  const handleToChange = (e) => {
    setTo(e);
  };

  const handleSearch = async (e) => {
    const search = e.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: search ?? "",
      };
      const response = await companyAgent(body);
      setData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="bg_bx">
        <div className="agent_head d_flx">
          <h2>Customer</h2>
          <div className="flt_search">
            <SearchBox onChange={handleSearch} />
            <Filter
              onFromChange={handleFromChange}
              onToChange={handleToChange}
              onClick={filter}
            />
          </div>
        </div>
        <div className="table_box">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>User ID</th>
                <th>Last Seen</th>
                {/* <th>Latest conversation</th> */}
              </tr>
            </thead>
            <tbody>
              <Skeleton loading={load} active block={true}>
                {data?.length ? (
                  data?.map((subs) => (
                    <tr key={subs?.id}>
                      <td>
                        <div className="ai_bot">
                          <figure>
                            {subs?.profile_picture?.media_file_url ? (
                              <img
                                src={subs?.profile_picture?.media_file_url}
                                alt="bot"
                              />
                            ) : (
                              <Avatar
                                style={{
                                  backgroundColor: "#ECF6FD",
                                  color: "#414DE1",
                                }}
                              >
                                {capitalizeFirstLetter(subs?.first_name)}
                              </Avatar>
                            )}
                          </figure>
                          <span>{subs?.first_name}</span>
                        </div>
                      </td>
                      <td>{subs?.id}</td>
                      <td>{timesAgo(subs?.created_at)}</td>
                      <td className="option_box">
                        <CustomerDelBolc
                          custId={subs?.id}
                          first_name={subs?.first_name}
                          fetchData={() => fetchData()}
                          active={subs?.is_active}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="no_dt">
                    <Empty />
                  </div>
                )}
              </Skeleton>
            </tbody>
          </table>
        </div>
        {data?.length && totalCnt > 10 ? (
          <PaginationBox
            defaultCurrent={1}
            current={current}
            onChange={onChange}
            total={totalCnt}
          />
        ) : null}
      </div>
    </>
  );
};
export default CustomerList;
