import React, { useState } from "react";
import { Dropdown, Modal } from "antd";
import { STORAGE_KEYS } from "../constants/storage";
import { getData } from "../constants";
import {
  useAddMessageMutation,
  useDeleteOptionMutation,
  useUpdateOptMutation,
} from "../services/botBuilder";
import { errorToast, successToast } from "../helpers";
import { Link } from "react-router-dom";

const AddMessage = ({ open, setOpen, nodeId, nodeName }) => {
  const [addMessage, setAddMessage] = useState("");
  const [editMessage, setEditMessage] = useState("");
  const [addOption] = useAddMessageMutation();
  const [deleteOpt] = useDeleteOptionMutation();
  const [updateOpt] = useUpdateOptMutation();
  const [addRequire, setAddRequire] = useState(false);
  const [editRequire, setEditRequire] = useState(false);

  const hide = () => {
    setOpen(false);
    if (addRequire) {
      setAddRequire(false);
    }
    if (editRequire) {
      setEditRequire(false);
    }
    if (addMessage) {
      setAddMessage("");
    }
    if (editMessage) {
      setEditMessage("");
    }
  };

  const handleAddOption = async () => {
    if (!addMessage) {
      setAddRequire(true);
      return;
    }
    try {
      const body = {
        name: addMessage,
        parent: nodeId,
      };
      const response = await addOption({
        body,
        prod_bot_id: getData(STORAGE_KEYS?.prod_bot_id),
      });
      console.log("response: ", response);
      if (response?.data?.code === 201) {
        successToast(response?.data?.message);
        setOpen(false);
      } else {
        errorToast(response?.data?.message);
      }
    } catch (error) {
      console.log("error adding option: ", error);
    } finally {
      hide();
    }
  };

  const handleEditOption = async () => {
    if (!editMessage) {
      setEditRequire(true);
      return;
    }
    try {
      const body = {
        name: editMessage,
        parent: nodeId,
      };
      if (nodeId) {
        const response = await updateOpt({ body, id: nodeId });
        console.log("response: ", response);
        if (response?.data?.code === 200) {
          successToast(response?.data?.message);
          setOpen(false);
        } else {
          errorToast(response?.data?.message);
        }
      } else {
        errorToast("Parent name cannot be change");
      }
    } catch (error) {
      console.log("error editing option: ", error);
    } finally {
      hide();
    }
  };

  const items = [
    {
      label: (
        <ul
          role="menu"
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="button-types-dropdown"
        >
          <li class="dropdown-header">Add Button</li>
          <li>
            <a>
              <figure>
                <img src="../images/bk_arrow.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Default Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Suggested Replies{" "}
              </span>
            </a>
          </li>
          <li>
            <a>
            <figure>
                <img src="../images/prefix.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Link Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Add links to button{" "}
              </span>
            </a>
          </li>
          <li>
            <a>
            <figure>
                <img src="../images/sb_btn.svg" alt="dots" />
              </figure>
              <span class="gBXVrn">
                {" "}
                Submit Button{" "}
              </span>
              <span class="bTkyvh">
                {" "}
                Send data on button press{" "}
              </span>
            </a>
          </li>
        </ul>
      ),
      key: "0",
    },
  ];

  const deleteOption = async () => {
    try {
      if (nodeId) {
        const response = await deleteOpt({ id: nodeId });
        console.log("response: ", response);
      } else {
        errorToast("Parent name cannot be delete");
      }
    } catch (error) {
      console.log("error deleting option: ", error);
    } finally {
      hide();
    }
  };

  const handleAddKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddOption();
    }
  };

  const handleEditKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleEditOption();
    }
  };

  return (
    <Modal
      // title={`${nodeName}`}
      open={open}
      // onOk={submit}
      onCancel={hide}
      width={490}
      footer={null}
      className="rgt_modal"
    >
      <ul className="flow_member">
        <li>
          <input
            type="text"
            placeholder="Add Text"
            value={addMessage}
            onChange={(e) => setAddMessage(e.target.value)}
            onKeyDown={handleAddKeyDown}
          />
          <button type="button" onClick={handleAddOption}>
            <figure>
              <img src="/images/edt.svg" alt="eye" />
            </figure>
          </button>
        </li>
        {addRequire && !addMessage && (
          <li style={{ color: "red", fontSize: "12px" }}>
            Please enter member name
          </li>
        )}
        <li>
          <input
            type="text"
            placeholder={nodeName ? `${nodeName}` : "Update text"}
            value={editMessage}
            onChange={(e) => setEditMessage(e.target.value)}
            onKeyDown={handleEditKeyDown}
          />
          <button type="button" onClick={handleEditOption}>
            <figure>
              <img src="/images/edit.svg" alt="eye" />
            </figure>
          </button>
        </li>
        {editRequire && !editMessage && (
          <li style={{ color: "red", fontSize: "12px" }}>
            Please enter member name
          </li>
        )}
        <li className="w_100">
          <button type="button" className="dlt_btn" onClick={deleteOption}>
            <figure>
              <img src="/images/delete.svg" alt="eye" />
            </figure>
            Delete text
          </button>
        </li>
      </ul>
      {/* <div className="edt_msg">
        <div className="scrl_bx">
          <div className="edt_cnt">
            <h2>
              <figure>
                <img src="../images/bot.png" alt="bot"></img>
              </figure>{" "}
              Bot message
            </h2>
            <p>Add bot responses to respond to what users say.</p>
          </div>
          <h3>Welcome Message</h3>
          <h4>Bot says</h4>
          <div className="py_ld">
            <p>Add text, buttons, images, custom payload messages</p>
            <p>
              <Link to="">personalize messages</Link> and{" "}
              <Link to="">dynamic message</Link>
            </p>
          </div>
          <div className="bot_mssg">
            <figure>
              <img src="../images/dots.svg" alt="dots" />
            </figure>
            <div className="bt_txtarea">
              <label>Bot's Message</label>
              <textarea placeholder="Create a custom welcome message to greet your users" />
              <figure>
                <img src="../images/usr.svg" alt="dots" />
              </figure>
            </div>
            <figure>
              <img src="../images/dlt.svg" alt="dots" />
            </figure>
          </div>
          <div className="bot_mssg">
            <figure>
              <img src="../images/dots.svg" alt="dots" />
            </figure>
            <div className="bt_txtarea">
              <label>Buttons</label>
              <div className="btn_add">
                <figure>
                  <img src="../images/dots.svg" alt="dots" />
                </figure>
                <div className="btn_inpt">
                  <input type="text"></input>
                  <figure>
                    <img src="../images/prefix.svg" alt="dots" />
                  </figure>
                </div>
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="topRight"
                >
                  <button type="button" className="buttons_add">
                    +
                  </button>
                </Dropdown>
              </div>
            </div>
            <figure>
              <img src="../images/dlt.svg" alt="dots" />
            </figure>
          </div>
          <div className="bot_mssg">
            <figure>
              <img src="../images/dots.svg" alt="dots" />
            </figure>
            <div className="bt_txtarea">
              <div className="upld_drag_img">
                <span>Drag & drop / Click here to upload image</span>
                <input type="file" />
              </div>
              <h3 className="text_center">OR</h3>
              <label>Image URL</label>
              <textarea placeholder="Please enter the image URL here" />
              <label>Caption (Optional)</label>
              <textarea placeholder="Please enter the image caption here" />
            </div>
            <figure>
              <img src="../images/dlt.svg" alt="dots" />
            </figure>
          </div>
          <div className="btn_grps">
            <button type="button">+ Text</button>
            <button type="button">+ Buttons</button>
            <button type="button">
              + More{" "}
              <img className="drp_img" src="../images/drp.svg" alt="img"></img>
              <div className="drp_dwn">
                <h3>RICH MESSAGES</h3>
                <ul>
                  <li>
                    <img src="../images/img.svg" alt="img" /> Image{" "}
                    <span>Image with caption</span>
                  </li>
                </ul>
              </div>
            </button>
          </div>
          <div className="dnc_msg">
            <h5>Dynamic Message</h5>
            <div className="csat_toggle d_flx">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="lgt_cnt">
            <p>
              Dynamic messaging helps you to fetch your custom data and send it
              to the end users. Below are the two options to send a dynamic
              message.
            </p>
            <div className="inln_cd">
              <div className="input_radio">
                <input
                  type="radio"
                  id="html1"
                  name="fav_language"
                  className="input-radio"
                />
                <label htmlFor="html1">Inline Code</label>
              </div>
            </div>
            <p>
              Write custom code to retrieve or send data to your database or API{" "}
              <Link to="">Know more</Link>
            </p>
            <div className="slc_bx"></div>
            <div className="inln_cd">
              <div className="input_radio">
                <input
                  type="radio"
                  id="html2"
                  name="fav_language"
                  className="input-radio"
                />
                <label htmlFor="html2">Webhook</label>
              </div>
            </div>
            <p>
              Connect your Webhook or API to retrieve or send data to your
              server or database <Link to="">Know more</Link>
            </p>
            <div className="slc_bx"></div>
          </div>
        </div>
        <div className="sv_cncl_btn">
          <button type="button" className="cncl_btn" onClick={hide}>
            Cancel
          </button>
          <button type="button" className="sv_btn">
            Save
          </button>
        </div>
      </div> */}
    </Modal>
  );
};

export default AddMessage;
