import SaveBtn from "../../../components/savebtn";
import InputBox from "../../../components/inputbox";
import "react-tabs/style/react-tabs.css";
import React, { useCallback, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  useAsignedChatMutation,
  useFilterCnvsCustMutation,
  useLazyGetagentListQuery,
  useQueueChatMutation,
  useReAssignListMutation,
  useResolvedChatMutation,
} from "../../../services/conversation";
import { DatePicker, Empty, Spin } from "antd";
import { errorToast } from "../../../helpers";

const ConversationLeftPanel = ({
  sendIdToParent,
  sendNameToParent,
  sendSelectedTab,
  AssignResponse,
}) => {
  const [agentList] = useLazyGetagentListQuery();
  const [queueList] = useQueueChatMutation();
  const [assignList] = useAsignedChatMutation();
  const [resolvedList] = useResolvedChatMutation();
  const [unResolved] = useReAssignListMutation();
  const [filterCnvsCust] = useFilterCnvsCustMutation();
  const { RangePicker } = DatePicker;
  const [clicked, setClicked] = useState("");
  const [select, setSelect] = useState("");
  const [queList, setQueList] = useState();
  const [asgnList, setAssignList] = useState();
  const [resolveList, setResovleList] = useState();
  const [unResolvedList, setUnResolvedList] = useState();
  const [agntId, setAgntId] = useState();
  const [load, setLoad] = useState(false);
  const [agtList, setAgtList] = useState();
  const [dateRange, setDateRange] = useState();
  const [tab, setTab] = useState();
  const [tabsSlt, setTabSlt] = useState();
  const [require, setRequire] = useState(false);
  const [totalCnt, setTotalCnt] = useState(10);
  const [showLoadMore, setShowLoadMore] = useState(false);

  const handleClick = () => {
    clicked ? setClicked("") : setClicked("conv_leftPanel click-state");
    const fetchAgentList = async () => {
      try {
        const response = await agentList();
        console.log('response: ', response);
        const responseData = response?.data?.data;
        console.log("response: ", responseData);
        if(response?.data?.code === 200){
          setShowLoadMore(true);
          setAgtList(responseData);
        }else{
          errorToast(response?.error?.data?.message)
        }
      } catch (error) {
        console.log("error: ", error);
      }
    };

    if(!clicked){
      fetchAgentList();
    }

    setTab("");
  };
  const filter = async () => {
    if (!tab || !dateRange || !agntId) {
      setRequire(true);
      console.log("enter condition");
      return;
    }

    try {
      setLoad(true);
      let body = {
        start_date: dateRange[0] ?? "",
        end_date: dateRange[1] ?? "",
        is_assigned: tab === "Assigned",
        is_resolved: tab === "Resolved",
        is_queued: tab === "Queued",
        assigned_to_agent: agntId ?? "",
      };
      const response = await filterCnvsCust(body);
      const responseData = response?.data?.data;

      if (tabsSlt === 0) {
        setQueList(responseData);
      } else if (tabsSlt === 1) {
        setAssignList(responseData);
      } else if (tabsSlt === 2) {
        setResovleList(responseData);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      clicked ? setClicked("") : setClicked("conv_leftPanel click-state");
      setLoad(false);
      setRequire(false);
    }
  };

  const handleAssign = async (id) => {
    setAgntId(id);
  };

  const fetchQueue = useCallback(
    async (search, loadMore) => {
      try {
        let body = {
          page: 1,
          length: loadMore ?? 10,
          search_value: search ?? "",
        };
        const response = await queueList(body);
        const responseData = response?.data?.data;
        console.log("queueList:>> ", responseData);
        setQueList(responseData);
        if (response?.data?.total_count > 10) {
          setShowLoadMore(true);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
    [queueList, setQueList]
  );

  const fetchAssign = async (search, loadMore) => {
    try {
      let body = {
        page: 1,
        length: loadMore ?? 10,
        search_value: search ?? "",
      };
      const response = await assignList(body);
      const responseData = response?.data?.data;
      console.log("assignList:>> ", responseData);
      setAssignList(responseData);
      if (response?.data?.total_count > 10) {
        setShowLoadMore(true);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const fetchResolve = async (search, loadMore) => {
    try {
      let body = {
        page: 1,
        length: loadMore ?? 10,
        search_value: search ?? "",
      };
      const response = await resolvedList(body);
      console.log("resolve++response: ", response);
      const responseData = response?.data?.data;
      setResovleList(responseData);
      if (response?.data?.total_count > 10) {
        setShowLoadMore(true);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const fetchUnresolve = useCallback(
    async (search, loadMore) => {
      try {
        let body = {
          page: 1,
          length: loadMore ?? 10,
          search_value: search ?? "",
        };
        const response = await unResolved(body);
        console.log("resolve++response: ", response);
        const responseData = response?.data?.data;
        setUnResolvedList(responseData);
        if (response?.data?.total_count > 10) {
          setShowLoadMore(true);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
    [unResolvedList, setUnResolvedList]
  );

  useEffect(() => {
    fetchQueue();

    if (AssignResponse === "assigned") {
      fetchQueue();
    } else if (AssignResponse === "reAssigned") {
      fetchUnresolve();
    }
  }, [agentList, fetchQueue, AssignResponse]);

  const handleSelect = (id, sesId, userName) => {
    if (sesId && id) {
      sendIdToParent(sesId);
      setSelect(id);
    }
    if (userName) {
      sendNameToParent(userName);
    }
  };

  const handleToChange = (_, dateString) => {
    setDateRange(dateString);
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    switch (tabsSlt) {
      case 0:
        fetchQueue(search);
        break;
      case 1:
        fetchAssign(search);
        break;
      case 2:
        fetchResolve(search);
        break;
      case 3:
        fetchUnresolve(search);
        break;
      default:
        fetchQueue(search);
        break;
    }
  };

  const handleTabSelection = (key) => {
    setTabSlt(key);
    sendSelectedTab(key);
    setSelect("");
    setShowLoadMore(false);
    sendIdToParent(null);
    switch (key) {
      case 0:
        fetchQueue();
        break;
      case 1:
        fetchAssign();
        break;
      case 2:
        fetchResolve();
        break;
      case 3:
        fetchUnresolve();
        break;
      default:
        fetchQueue();
    }
  };

  const handleCheckedTab = (key) => {
    setTab(key);
    switch (key) {
      case "Queued":
        setTabSlt(0);
        break;
      case "Assigned":
        setTabSlt(1);
        break;
      case "Resolved":
        setTabSlt(2);
        break;
      case "Unresolved":
        setTabSlt(3);
        break;
      default:
        setTabSlt(0);
    }
  };

  const handleLoadMore = () => {
    try {
      switch (tabsSlt) {
        case 0:
          fetchQueue("", totalCnt + 10);
          break;
        case 1:
          fetchAssign("", totalCnt + 10);
          break;
        case 2:
          fetchResolve("", totalCnt + 10);
          break;
        case 3:
          fetchUnresolve("", totalCnt + 10);
          break;
        default:
          fetchQueue("", totalCnt + 10);
          break;
      }
    } finally {
      setTotalCnt(totalCnt + 10);
    }
  };

  return (
    // <div className="conv_leftPanel" { `base-state ${clicked && 'click-state'}`>
    <div className={clicked || "conv_leftPanel"}>
      <Spin spinning={load} size="medium">
        <form className="serach_form">
          <InputBox
            type="search"
            placeholder="Search"
            onChange={handleSearch}
          />
          <figure className="filter_btn" onClick={handleClick}>
            <img className="flt_img" src="/images/icon_filter.svg" alt="" />
            <img className="crs_img" src="/images/close.svg" alt="" />
          </figure>
        </form>
        <div className="assigned_tab">
          <Tabs
            selectedIndex={tabsSlt || 0}
            onSelect={(index) => handleTabSelection(index)}
          >
            <TabList>
              <Tab>Queued</Tab>
              <Tab>Assigned</Tab>
              <Tab>Resolved</Tab>
              <Tab>Unresolved</Tab>
            </TabList>
            <TabPanel>
              <ul className="user_conversation">
                {queList?.length ? (
                  queList?.map((queue) => (
                    <li
                      className={select === queue?.id ? "active" : null}
                      key={queue?.id}
                      onClick={() => handleSelect(queue?.id, queue?.session_id)}
                    >
                      <figure className="filter_btn">
                        <img
                          src={
                            queue?.profile?.media_file_url
                              ? queue?.profile?.media_file_url
                              : "/images/frame.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div>
                        <h3>{queue?.user_name}</h3>
                        <p>Status has been changed this co. </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </ul>
            </TabPanel>
            <TabPanel>
              <ul className="user_conversation">
                {asgnList?.length ? (
                  asgnList?.map((assign) => (
                    <li
                      className={select === assign?.id ? "active" : null}
                      key={assign?.id}
                      onClick={() =>
                        handleSelect(
                          assign?.id,
                          assign?.session_id,
                          assign?.agent
                        )
                      }
                    >
                      <figure className="filter_btn">
                        <img
                          src={
                            assign?.profile?.media_file_url
                              ? assign?.profile?.media_file_url
                              : "/images/frame.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div>
                        <h3>{assign?.user_name}</h3>
                        <p>Status has been changed this co. </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </ul>
            </TabPanel>
            <TabPanel>
              <ul className="user_conversation">
                {resolveList?.length ? (
                  resolveList?.map((resolve) => (
                    <li
                      className={select === resolve?.id ? "active" : null}
                      key={resolve?.id}
                      onClick={() =>
                        handleSelect(
                          resolve?.id,
                          resolve?.session_id,
                          resolve?.agent
                        )
                      }
                    >
                      <figure className="filter_btn">
                        <img
                          src={
                            resolve?.profile?.media_file_url
                              ? resolve?.profile?.media_file_url
                              : "/images/frame.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div>
                        <h3>{resolve?.user_name}</h3>
                        <p>Status has been changed this co. </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </ul>
            </TabPanel>
            <TabPanel>
              <ul className="user_conversation">
                {unResolvedList?.length ? (
                  unResolvedList?.map((unResolved) => (
                    <li
                      className={select === unResolved?.id ? "active" : null}
                      key={unResolved?.id}
                      onClick={() =>
                        handleSelect(
                          unResolved?.id,
                          unResolved?.session_id,
                          unResolved?.agent
                        )
                      }
                    >
                      <figure className="filter_btn">
                        <img
                          src={
                            unResolved?.profile?.media_file_url
                              ? unResolved?.profile?.media_file_url
                              : "/images/frame.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div>
                        <h3>{unResolved?.user_name}</h3>
                        <p>Status has been changed this co. </p>
                      </div>
                    </li>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </ul>
            </TabPanel>
          </Tabs>
          {showLoadMore && (
            <p className="load_more" onClick={handleLoadMore}>
              <i className="fa-solid fa-arrow-down" />
              Load more
            </p>
          )}
        </div>
        <div className="filter_div">
          <div className="d_flx">
            <h2>Filter</h2>
            {/* <span>Clear All</span> */}
          </div>
          <div className="date_bx">
            <label>Date Range</label>
            <div className="input_box pd_input">
              <RangePicker onChange={handleToChange} />
            </div>
          </div>
          <div className="box_flt">
            <div className="d_flx">
              <label>Status</label>
              <span onClick={() => handleCheckedTab("")}>Clear All</span>
            </div>
            <div className="checkbox_list d_flx">
              <div className="input_radio">
                <input
                  type="radio"
                  id="html1"
                  name="fav_language"
                  value={"Assigned"}
                  className="input-radio"
                  onChange={(e) => handleCheckedTab(e.target.value)}
                  checked={tab === "Assigned"}
                />
                <label htmlFor="html1">Open</label>
              </div>
              <div className="input_radio">
                <input
                  type="radio"
                  id="html2"
                  name="fav_language"
                  value={"Resolved"}
                  className="input-radio"
                  onChange={(e) => handleCheckedTab(e.target.value)}
                  checked={tab === "Resolved"}
                />
                <label htmlFor="html2">Resolved</label>
              </div>
              <div className="input_radio">
                <input
                  type="radio"
                  id="html3"
                  name="fav_language"
                  value={"Queued"}
                  className="input-radio"
                  onChange={(e) => handleCheckedTab(e.target.value)}
                  checked={tab === "Queued"}
                />
                <label htmlFor="html3">Queued</label>
              </div>
              <div className="input_radio">
                <input
                  type="radio"
                  id="html4"
                  name="fav_language"
                  value={"Unresolved"}
                  className="input-radio"
                  onChange={(e) => handleCheckedTab(e.target.value)}
                  checked={tab === "Unresolved"}
                />
                <label htmlFor="html4">Unresolved</label>
              </div>
              {require && !tab && (
                <p style={{ color: "red", marginTop: "0", fontSize: "14px" }}>
                  {" "}
                  *Please select at least one status
                </p>
              )}
            </div>
          </div>
          <div className="box_flt">
            <div className="d_flx">
              <label>Assigned To</label>
              <span onClick={() => setAgntId("")}>Clear All</span>
            </div>
            <select
              name="box"
              id="bd"
              value={agntId}
              onChange={(e) => handleAssign(e.target.value)}
            >
              <option value="">Everyone</option>
              {agtList?.length
                ? agtList?.map((agent) => (
                    <option value={agent.id} key={agent.id}>
                      {`${agent.first_name} ${agent.last_name}`}
                    </option>
                  ))
                : null}
            </select>
            {require && !agntId && (
              <p style={{ color: "red", marginTop: "0", fontSize: "14px" }}>
                {" "}
                *Please provide date range
              </p>
            )}
          </div>
          <SaveBtn title="Apply" onClick={filter} />
        </div>
      </Spin>
    </div>
  );
};
export default ConversationLeftPanel;
