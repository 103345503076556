import React from "react";

const OurBot = () => {
  return (
    <div className="conta_iner" data-aos="zoom-in">
      <div className="text_center max_60">
        <h2>Say Hello to Our Bot</h2>
        <p className="gry_txt">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta
          quam non dui scelerisque, eget ornare lacus vestibulum. Quisque
          molestie lorem nunc, vel ullamcorper ligula volutpat in. Nullam sed
          rutrum ligula.
        </p>
      </div>
      <div className="d_flx al_sc aln_itm_strch">
        <div className="box_bot">
          <h3>98%</h3>
          <p>of questions recognized by tunixbot</p>
        </div>
        <div className="box_bot">
          <h3>1h</h3>
          <p>is all you need to add intuitive automation</p>
        </div>
        <div className="box_bot">
          <h3>12%</h3>
          <p>boost in customer satisfaction</p>
        </div>
      </div>
    </div>
  );
};

export default OurBot;
