import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const authapi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),
    logIn: builder.mutation({
      query: (body) => ({
        url: END_POINTS.logIn,
        method: "POST",
        body,
      }),
    }),
    signUpOtp: builder.mutation({
      query: (body) => ({
        url: END_POINTS.signUpOtp,
        method: "POST",
        body,
      }),
    }),
    userFgtPass: builder.mutation({
      query: (body) => ({
        url: END_POINTS.userFgtPass,
        method: "POST",
        body,
      }),
    }),
    userFgtPassOtp: builder.mutation({
      query: (body) => ({
        url: END_POINTS.userFgtPassOtp,
        method: "POST",
        body,
      }),
    }),
    newPassword: builder.mutation({
      query: (body) => ({
        url: END_POINTS.newPassward,
        method: "POST",
        body,
      }),
    }),
    getUserDetails: builder.mutation({
      query: () => ({
        url: END_POINTS.getUserDetails,
        method: "GET",
      }),
    }),
    editProfile: builder.mutation({
      query: (body) => ({
        url: END_POINTS.editProgile,
        method: "PUT",
        body,
      }),
    }),
    changeUserPass: builder.mutation({
      query: (body) => ({
        url: END_POINTS.changePass,
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: END_POINTS.logout,
        method: "POST",
        body,
      }),
    }),
    socialLogin: builder.mutation({
      query: (body) => ({
        url: END_POINTS.socialLogin,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLogInMutation,
  useSignUpMutation,
  useChangeUserPassMutation,
  useEditProfileMutation,
  useGetUserDetailsMutation,
  useLogoutMutation,
  useNewPasswordMutation,
  useSignUpOtpMutation,
  useUserFgtPassOtpMutation,
  useUserFgtPassMutation,
  useSocialLoginMutation,
} = authapi;
