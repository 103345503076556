import React from "react";
import Layout from "../../layout";
import { Server } from "./features/server";

function ServerNotFound() {
  return (
    <Layout>
      <Server />
    </Layout>
  );
}

export default ServerNotFound;
