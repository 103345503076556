// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { removeData } from '../constants';
import { STORAGE_KEYS } from '../constants/storage';

const initialState = {
  user: null,
  token: null,
  tempToken: null,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: (state, action) => {
      removeData(STORAGE_KEYS.token);
      state.user = null;
      state.token = null;
    },
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    temporaryToken: (state, action) => {
      state.tempToken = action.payload.tempToken;
    },
  },
});

export const { setCredentials, temporaryToken, resetAuth } = authSlice.actions;

export const getCurrentUser = (state) => state.auth.user;
export const getToken = (state) => state.auth.token;

export default authSlice.reducer;
