import React from "react";
import InputBox from "../../../components/inputbox";

const CustomQuery = () => {
  return (
    <>
      {/* <div className="structure_area"> */}
        <div className="agent_head d_flx m_0">
          <h2>Custom Queries<span> (Max 5 Queries)</span></h2>
        </div>
        <div className="add_query">
          <InputBox placeholder="Add Query" type="text" />
          <i className="fa-solid fa-plus"></i>
        </div>
      {/* </div> */}
    </>
  );
};
export default CustomQuery;
