import React from "react";
import { useSelector } from "react-redux";

const Stepper = () => {

  const stepper = useSelector((state) => state?.feature?.stepperData);

  return (
    <>
      <ul className="bot_stepper">
        <li className="bot_complete">
          <div className="round_box">
            {stepper[0] === "template" ? < i className="fa-solid fa-check" /> : "1"}
          </div>
          <p>Select template</p>
        </li>
        <li className="present_bot">
          <div className="round_box">
            {stepper[1] === "botProfile" ? <i className="fa-solid fa-check" /> : "2"}
          </div>
          <p>Bot Profile</p>
        </li>
        <li className="upcoming_bot">
          {" "}
          <div className="round_box">
            {stepper[2] === "humanHandoff" ? <i className="fa-solid fa-check" /> : "3"}
          </div>
          <p>Human Handoff</p>
        </li>
      </ul>
    </>
  );
};
export default Stepper;
