import React, { useState } from "react";
import Layout from "../../layout";
import ConversationLeftPanel from "./features/left-panel";
import ConversationPanel from "./features/conversation-panel";

const AgentConversation = () => {
  const [idFromLeftPanel, setIdFromLeftPanel] = useState(null);
  const [tabSlt, setTabSlt] = useState(null);
  const [EndChatResponse, setEndChatResponse] = useState(null);

  const receiveIdFromLeftPanel = (id) => {
    setIdFromLeftPanel(id);
  };

  const receiveSelectedTab = (tab) => {
    setTabSlt(tab);
  };

  const receiveEndChatResponse = (key) => {
    setEndChatResponse(key);
  };

  return (
    <>
      <Layout>
        <section className="main_area">
          <div className="conv_wrap">
            <ConversationLeftPanel
              sendIdToParent={receiveIdFromLeftPanel}
              sendSelectedTab={receiveSelectedTab}
              EndChatResponse={EndChatResponse}
            />
            <ConversationPanel
              idFromLeftPanel={idFromLeftPanel}
              tabSlt={tabSlt}
              sendChatEnd={receiveEndChatResponse}
            />
          </div>
        </section>
      </Layout>
    </>
  );
};
export default AgentConversation;
