import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const manageCust = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        customerList: builder.mutation({
            query: (body) => ({
                url: END_POINTS.customerList,
                method: 'POST',
                body,
            }),
        }),
        filterCust: builder.mutation({
            query: (body) => ({
                url: END_POINTS.filterCust,
                method: 'POST',
                body,
            }),
        }),
        deleteCust: builder.mutation({
            query: (id) => ({
                url: `${END_POINTS.deleteCust}${id}/`,
                method: 'DELETE',
            }),
        }),
        blockCust: builder.mutation({
            query: ({ body, custId }) => ({
                url: `${END_POINTS.blockCust}${custId}/`,
                method: 'PUT',
                body
            }),
        }),
        getBlkCust: builder.query({
            query: (id) => ({
                url: `${END_POINTS.getBlkCust}${id}/`,
                method: 'GET',
            }),
        })
    }),
});

export const
    {
        useCustomerListMutation,
        useFilterCustMutation,
        useDeleteCustMutation,
        useBlockCustMutation,
        useLazyGetBlkCustQuery,
    } = manageCust;
