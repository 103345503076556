/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import SettingSidebar from "../../components/settingsidebar";
import AwayMessage from "./features/awaymessage";

const AgentAvailability = () => {
  return (
    <>
      <Layout>
        <section className="setting_page d_flx align_top">
          <SettingSidebar />
          <AwayMessage />
        </section>
      </Layout>
    </>
  );
};
export default AgentAvailability;
