import { Link } from "react-router-dom";
import Email from "../../../components/email";
import Password from "../../../components/password";
import Checkbox from "../../../components/checkbox";
import LoginButton from "../../../components/loginbutton";
import GoogleButton from "../../../components/googlebutton";
import FormHead from "../../../components/formhead";
import { useNavigate } from "react-router-dom/dist";
import { useDispatch } from "react-redux";
import { storeData } from "../../../constants";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../../utils/firebaseKeys";
import React, { useEffect, useState } from "react";
import {
  useLogInMutation,
  useSocialLoginMutation,
} from "../../../services/api";
import { STORAGE_KEYS, getData } from "../../../constants/storage";
import { errorToast, successToast } from "../../../helpers/showToast";
import { setCredentials } from "../../../reducers/authSlice";
import { Spin } from "antd";
import { isEmail } from "../../../utils/validations";

const FormLogin = () => {
  const history = useNavigate();

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [logIn] = useLogInMutation();
  const [require, setRequire] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [socialLogin] = useSocialLoginMutation();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
    storeData(STORAGE_KEYS.userData, email);
    storeData(STORAGE_KEYS.userData, pass);
  };
  useEffect(() => {
    const rememberMePreference = getData(STORAGE_KEYS.rememberMe);
    if (rememberMePreference === true) {
      setRememberMe(true);
      const store = getData(STORAGE_KEYS.credentials);
      if (store) {
        setEmail(store.mail);
        setPass(store.pass);
      }
    }
  }, []);

  const Login = async () => {
    if (!email || !pass || !isEmail(email)) {
      console.log("enter details");
      setRequire(true);
      return;
    }
    let body = {
      email: email,
      password: pass,
      device_type: "Web",
      device_token: getData(STORAGE_KEYS.fcmToken),
    };
    let response;
    try {
      setLoad(true);
      response = await logIn(body);
      console.log("Login response: ", response);
      const to_ken = response?.data?.data?.access_token;
      const userDetails = response?.data?.data;
      let bdy = {
        mail: email,
        pass: pass,
      };
      if (rememberMe) {
        storeData(STORAGE_KEYS.rememberMe, true);
        storeData(STORAGE_KEYS.credentials, bdy);
      } else {
        storeData(STORAGE_KEYS.rememberMe, false);
      }
      if (response?.data?.code === 200) {
        successToast(response?.data?.message);
        storeData(STORAGE_KEYS.token, to_ken);
        storeData(STORAGE_KEYS.userData, userDetails);
        dispatch(
          setCredentials({
            user: userDetails,
            token: to_ken,
          })
        );
        if (response?.data?.data?.role === 2) {
          history("/agent-dashboard");
        } else {
          if (response?.data?.data?.chatbot_exist) {
            history("/dashboard");
          } else {
            history("/botintegration");
          }
        }
      } else {
        errorToast(response?.error?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
      errorToast(response?.error?.data?.message);
    } finally {
      setLoad(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Login();
    }
  };

  const loginWithGoogle = async () => {
    const googleRes = await signInWithPopup(auth, provider);
    let gRes;
    if (googleRes?._tokenResponse) {
      try {
        setLoad(true);
        let body = {
          social_login_id: googleRes?._tokenResponse?.localId,
          social_login_type: googleRes?._tokenResponse?.localId,
          social_type: googleRes?._tokenResponse?.localId,
          bussiness_name: googleRes?._tokenResponse?.displayName,
          first_name: googleRes?._tokenResponse?.firstName,
          last_name: googleRes?._tokenResponse?.lastName,
          email: googleRes?._tokenResponse?.email,
          profile_picture: googleRes?._tokenResponse?.photoUrl,
        };
        gRes = await socialLogin(body);
        console.log("socialLogin: ", gRes?.data);
        const to_ken = gRes?.data?.data?.access_token;
        const userDetails = gRes?.data?.data;
        if (gRes?.data?.code === 200) {
          successToast(gRes?.data?.message);
          storeData(STORAGE_KEYS.token, to_ken);
          storeData(STORAGE_KEYS.userData, userDetails);
        }
        if (getData(STORAGE_KEYS.token)) {
          history("/dashboard");
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    }
  };

  return (
    <>
      <Spin spinning={load}>
        <section className="login_page">
          <div className="login_bx">
            <div className="lg_frm">
              <FormHead title="Login to TunixBot" />
              <Email
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
                login={true}
              />
              <Password
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                require={require}
                login={true}
                onKeyDown={handleKeyPress}
              />
              <div className="d_flx mb_40">
                <Checkbox
                  onChange={handleRememberMeChange}
                  checked={rememberMe}
                />
                <Link to="/forgotpassword">Forgot Password?</Link>
              </div>
              <LoginButton name="Login" click={Login} />
              <GoogleButton name="Login" onClick={loginWithGoogle} />
              <div className="an_aacount">
                <p>
                  Don’t have an account ? <Link to="/signup">Sign Up</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Spin>
    </>
  );
};

export default FormLogin;
