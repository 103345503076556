import React from "react";

const ProfileHead = () => {
  return (
    <>
      <div className="d_flx prf_head">
        <div>
          <h1>Contact Us</h1>
          <p>Enter your details</p>
        </div>  
      </div>
    </>
  );
};
export default ProfileHead;
