import React from "react";
import FlowDesigner from "../../components/flowdesigner";
import FlowStructure from "./features/flowstructure";
import Layout from "../../layout";
import BuildView from "../botbuilder/features/buildview";
import { useParams } from "react-router-dom";
import { IntentView } from "../../components/intentview";
import { SmallTalk } from "../../components/smalltalk";
import LiveChatView from "../../components/livechatview";

const DefaultFlow = () => {
  const { id } = useParams();

  const renderStructure = () => {;
    switch (id) {
      case "buildView":
        return <BuildView />;
      case "intentView":
        return <IntentView />;
      case "smallTalk":
        return <SmallTalk />;
      default:
        return null;
    }
  };

  return (
    <>
      <Layout>
        <FlowDesigner />
        {id === "flowStructure" && <FlowStructure />}
        <div className="structure_area flx_strch">{renderStructure()}
        {id !== "flowStructure" && <LiveChatView />} 
        </div>
      </Layout>
    </>
  );
};
export default DefaultFlow;
