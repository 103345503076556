/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import LoginButton from "./loginbutton";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import {
  useSignUpMutation,
  useSignUpOtpMutation,
  useUserFgtPassOtpMutation,
} from "../services/api";
import { errorToast, successToast } from "../helpers/showToast";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const VerifyModal = ({ setOpen, open, email, data, bdy, page }) => {
  const [forgetPasswordOtp] = useUserFgtPassOtpMutation();
  const [signUp] = useSignUpMutation();
  const [signUpOtp] = useSignUpOtpMutation();
  const [otp, setOtp] = useState();
  const [require, setRequire] = useState(false);
  const [count, setCount] = useState(30);
  const [load, setLoad] = useState(false);
  let history = useNavigate();

  function closeModal() {
    setCount(0);
    setOtp("");
    setOpen(false);
    setRequire(false);
  }

  const verify = async () => {
    if (!otp) {
      console.log("enter value");
      setRequire(true);
      return;
    }
    if (data === "Reset_Password") {
      let response;
      let body = {
        email: email,
        otp: otp,
      };
      try {
        setLoad(true);
        response = await forgetPasswordOtp(body);
        if (response?.data?.code === 200) {
          successToast(response?.data?.message);
          history("/resetpassword", { state: { key: email } });
        } else {
          errorToast(response?.error?.data?.message);
        }
      } catch (error) {
        errorToast(response?.error?.data?.message);
      } finally {
        setOtp("");
        setRequire(false);
        setLoad(false);
      }
    } else if (data === "signup") {
      let response;
      try {
        let body = {
          email: email,
          otp: otp,
        };
        setLoad(true);
        response = await signUpOtp(body);
        console.log("response: ", response);
        successToast(response?.data?.message);
        if (response?.data?.code === 200) {
          if (page === "add_agent") {
            history("/employees");
          } else {
            history("/login");
          }
        } else {
          errorToast(response?.error?.data?.message);
        }
      } catch (error) {
        errorToast(response?.error?.data?.message);
      } finally {
        setOtp("");
        setRequire(false);
        setLoad(false);
      }
    } else {
      console.log("Nothing is there");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      verify();
    }
  };

  const resend = async () => {
    let response;
    try {
      setLoad(true);
      response = await signUp(bdy);
      successToast(response?.data?.message);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      errorToast(response?.error?.data?.message);
    } finally {
      setLoad(false);
      setCount(30);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  useEffect(() => {
    if (open) {
      setCount(30);
    }
  }, [open]);

  return (
    <div>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={open}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Spin spinning={load} size="medium">
          <div className="verify_email">
            <figure className="email_icon">
              <img src="/images/email.svg" alt="image" />
            </figure>
            <h2>Verify your email address</h2>
            <div className="otp-input">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                shouldAutoFocus
                isInputNumeric
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 1rem",
                  fontSize: "1.5rem",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                }}
                renderInput={(props) => (
                  <input {...props} onKeyDown={(e) => handleKeyPress(e)} />
                )}
              />
            </div>
            {require
              ? !otp && (
                  <p
                    style={{
                      color: "red",
                      fontSize: 15,
                      margin: "25px",
                    }}
                  >
                    Please enter valid OTP
                  </p>
                )
              : null}
            <p>
              We sent you an email to verify the email. Please check the email
              to OTP
            </p>
            {count > 0 ? (
              <p>OTP will expire in {count} seconds</p>
            ) : (
              <p>
                OTP has expired {` `}
                <a onClick={resend}>Resend</a>
              </p>
            )}
            <LoginButton name="verify" click={verify} />
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
export default VerifyModal;
