import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const botBuilder = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        defaultFlow: builder.query({
            query: ({ prod_bot_id }) => ({
                url: `${END_POINTS.defaultFlow}${prod_bot_id}/`,
                method: 'GET',
            }),
        }),
        addMessage: builder.mutation({
            query: ({ body, prod_bot_id }) => ({
                url: `${END_POINTS.addMessage}${prod_bot_id}/`,
                method: 'POST',
                body,
            }),
        }),
        deleteOption: builder.mutation({
            query: ({ id }) => ({
                url: `${END_POINTS.deleteOption}${id}/`,
                method: 'DELETE',
            }),
        }),
        updateOpt: builder.mutation({
            query: ({ body, id }) => ({
                url: `${END_POINTS.updateOpt}${id}/`,
                method: 'PUT',
                body,
            }),
        }),
    }),
});

export const {
    useLazyDefaultFlowQuery,
    useAddMessageMutation,
    useDeleteOptionMutation,
    useUpdateOptMutation,
} = botBuilder;
