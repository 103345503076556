/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import {
  useLazyThemeByIdQuery,
  useUpdateFallbackMutation,
} from "../../../services/settings";
import { successToast } from "../../../helpers";
import useAuth from "../../../hooks/useAuth";

const Emails = () => {
  const [load, setLoad] = useState(false);
  const [fallbBackEmail] = useLazyThemeByIdQuery();
  const [saveCsat] = useUpdateFallbackMutation();
  const [fEmial, setFEmail] = useState(0);
  const userData = useAuth();

  useEffect(() => {
    const fetchCsatData = async () => {
      try {
        setLoad(true);
        const response = await fallbBackEmail(
          userData?.business_name?.bussiness_id
        );
        const responseData = response.data.data;
        console.log("response: ", responseData);
        setFEmail(responseData.send_fallback_emails);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchCsatData();
  }, [fallbBackEmail, userData?.business_name?.bussiness_id]);

  const saveData = async () => {
    const companyId = await userData?.business_name?.bussiness_id;
    const valueFmail = !fEmial;
    try {
      setLoad(true);
      let body = {
        send_fallback_emails: valueFmail,
      };
      const response = await saveCsat({ body, id: companyId });
      console.log("response>>>>>>>>>>>>>: ", response);
      successToast(response?.data?.message);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="stng_rhs">
        <div className="agent_head">
          <h2>Fallback Emails</h2>
          <p>
            We send fallback emails to your users and/or your team members if
            they miss any new messages.
          </p>
          <hr />
        </div>
        <div className="csat_toggle d_flx">
          <h3>Allow Tunixbot to send all fallback emails</h3>
          <label className="switch">
            <input
              type="checkbox"
              checked={fEmial}
              onChange={(e) => setFEmail(e.target.checked)}
              onClick={saveData}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="gr_para">
          <p>
            We will send fallback emails to your users and/or your team members
            if they miss any new messages
          </p>
        </div>
      </div>
    </>
  );
};
export default Emails;
