/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import TemplateBot from "./features/template";
import BotSidebar from "../../components/botsidebar";
import Stepper from "../../components/stepper";

const BotTemplate = () => {
  return (
    <>
      <Layout>
        <section className="integration_main">
          {/* <BotSidebar /> */}
          <Stepper />
          <TemplateBot />
        </section>
      </Layout>
    </>
  );
};
export default BotTemplate;
