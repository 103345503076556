/* eslint-disable jsx-a11y/img-redundant-alt */
import { successToast } from "../../../helpers/showToast";
import { Empty, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  useBuySbsrptnMutation,
  useGetSubscriptionMutation,
} from "../../../services/settings";

const SubscriptionList = () => {
  const [subsListingData] = useGetSubscriptionMutation();
  const [buySubscription] = useBuySbsrptnMutation();
  const [subsData, setSubsData] = useState();
  const [load, setLoad] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: 1,
        length: 10,
        search_value: "",
      };
      const response = await subsListingData(body);
      console.log("response: ", response.data.data);
      setSubsData(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [subsListingData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleBuySubs = async (id) => {
    let res;
    try {
      let body = {
        bought_subscription: id,
      };
      res = await buySubscription(body);
      successToast(res.data.message);
    } catch (error) {
      console.log("errrorr", error);
    } finally {
      fetchData();
    }
  };

  return (
    <>
      <div className="stng_rhs">
        <div className="agent_head">
          <h2>Subscription Management</h2>
          <p>Choose or upgrade to the best plan which fits your needs</p>
          <hr />
        </div>
        <Spin spinning={load} size="medium">
          {subsData?.length ? (
            <div className="d_flx subs_flx">
              {subsData?.map((subs) => (
                <div className="subs_one" key={subs.id}>
                  <div className="subs_head d_flx">
                    <h3>{subs.name}</h3>
                    <h4>$ {subs.price}/yr</h4>
                  </div>
                  <p>{subs.description}</p>
                  <ul>
                    <li>You can add upto {subs.agent_count} Agent</li>
                    <li>Choose from {subs.agent_count} avatar counts</li>
                    <li>Train your bot with {subs.queries_count} queries</li>
                  </ul>
                  <button
                    type="button"
                    className="lite_bt"
                    onClick={() => handleBuySubs(subs.id)}
                  >
                    Choose {subs.name}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="mange_bot_no_dt">
              <Empty description="No Available Subscription" />
            </div>
          )}
        </Spin>
      </div>
    </>
  );
};
export default SubscriptionList;
