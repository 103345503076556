/* eslint-disable jsx-a11y/img-redundant-alt */

import AddMessage from "../../../components/addMessage";
import { getData } from "../../../constants";
import { STORAGE_KEYS } from "../../../constants/storage";
import React, { useEffect, useState } from "react";
import { useLazyDefaultFlowQuery } from "../../../services/botBuilder";
import Tree from "react-d3-tree";
import { Dropdown, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const FlowStructure = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [getDefaultFlow] = useLazyDefaultFlowQuery();
  const [nodeId, setNodeId] = useState("");
  const [nodeName, setNodeName] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const prod_bot_id = getData(STORAGE_KEYS?.prod_bot_id);
  const history = useNavigate();

  let treeData = [
    {
      name: "start",
      children: [
        {
          value: "Bot message",
          name: "welcome message",
          id: 339,
          children: [
            {
              value: "User message",
              name: "qww",
              id: 340,
              children: [],
            },
            {
              value: "User message",
              name: "ssss",
              id: 342,
              children: [],
            },
          ],
        },
        {
          name: "Default fallback",
          children: [
            {
              value: "Bot message",
              name: "Fallback Answer",
              id: 344,
              children: [],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDefaultFlow({
          prod_bot_id: prod_bot_id,
        });
        console.log("response: ", response?.data?.data);
        if (response?.data?.data) {
          setData(response?.data?.data);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    };
    if (prod_bot_id) {
      fetchData();
    } else {
      history("/managebot");
    }
  }, [getDefaultFlow, open]);

  const handleNodeClick = (key) => {
    console.log("key?.name: ", data?.name);
    if (data?.name) {
      setNodeId(key?.id);
      setNodeName(key?.name);
      setOpen(true);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(0.1, prevZoom - 0.1));
  };

  const reset = () => {
    setPosition({ x: 0, y: 0 });
    setZoomLevel(1);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [parentId, setParentId] = useState("");

  const handleHover = (key) => {
    setIsHovered(true);
    if (key?.id) {
      setParentId(key?.id);
    } else {
      setParentId("");
    }
  };

  const renderDropdownItems = (nodeDatum) => {
    return [
      {
        label: (
          <div className="drop_down" onMouseLeave={handleLeave}>
            <p>
              {" "}
              <img src="/images/edit.svg" alt="notification"></img>
              Edit message
            </p>
            <p>
              {" "}
              <img src="/images/delete.svg" alt="notification"></img>
              Delete
            </p>
          </div>
        ),
        key: "0",
        title: "Message Options",
      },
    ];
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const renderCustomNodeElement = ({ nodeDatum }) => {
    const truncatedName = nodeDatum?.name?.substring(0, 10);

    const tempSvg = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    const tempText = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );
    tempText.textContent = truncatedName;
    tempText.setAttribute("font-size", 20);
    tempSvg.appendChild(tempText);
    document.body.appendChild(tempSvg);

    const bbox = tempText.getBBox();

    const rectWidth = bbox.width + 30;
    const rectHeight = 40;

    const handleFillNode = () => {
      if (nodeDatum?.value) {
        switch (nodeDatum?.value) {
          case "User message":
            return "rgb(255 204 102";
          case "Bot message":
            return "rgb(211 214 255)";
          default:
            return "#fff";
        }
      } else {
        switch (nodeDatum?.name) {
          case "start":
            return "#fff";
          case "Default fallback":
            return "rgb(145 158 173)";
          default:
            return "#fff";
        }
      }
    };

    const handleStrokeNode = () => {
      if (nodeDatum?.value) {
        switch (nodeDatum?.value) {
          case "User message":
            return "rgb(172, 127, 52)";
          case "Bot message":
            return "#414de1";
          default:
            return "#414de1";
        }
      } else {
        switch (nodeDatum?.name) {
          case "start":
            return "rgb(71, 70, 154)";
          case "Default fallback":
            return "rgb(92, 102, 119)";
          default:
            return "rgb(71, 70, 154)";
        }
      }
    };

    const handleText = () => {
      if (nodeDatum?.value) {
        switch (nodeDatum?.value) {
          case "User message":
            return "rgb(60, 75, 80)";
          case "Bot message":
            return "#414de1";
          default:
            return "rgb(60, 75, 80)";
        }
      } else {
        switch (nodeDatum?.name) {
          case "start":
            return "rgb(71, 70, 154)";
          case "Default fallback":
            return "#fff";
          default:
            return "rgb(71, 70, 154)";
        }
      }
    };

    return (
      <Tooltip
        title={nodeDatum?.name}
        placement="top"
        arrow
        color="rgb(211 214 255 / 99%)"
        overlayInnerStyle={{ color: "#414de1" }}
      >
        <g onMouseEnter={() => handleHover(nodeDatum)}>
          <rect
            width={rectWidth}
            height={rectHeight}
            x={0}
            y={-20}
            rx={20}
            ry={20}
            style={{
              fill: handleFillNode(),
              stroke: handleStrokeNode(),
              cursor: "pointer",
              strokeWidth: 1,
              strokeOpacity: 0.8,
            }}
            onClick={() => handleNodeClick(nodeDatum)}
          />
          <text
            x="16"
            y="5"
            fontSize={18}
            fontWeight={"lighter"}
            stroke={handleText()}
            onClick={() => handleNodeClick(nodeDatum)}
          >
            {truncatedName + (nodeDatum?.name?.length > 10 ? "..." : "")}
          </text>
          <text
            x="12"
            y="-26"
            fontSize={12}
            fontWeight={"lighter"}
            stroke="rgb(92, 102, 119)"
          >
            {nodeDatum?.value}
          </text>
          {isHovered && parentId === nodeDatum?.id && (
            <Dropdown
              menu={{ items: renderDropdownItems(nodeDatum) }}
              trigger={["click"]}
              placement="bottom"
            >
              <text
                x="18"
                y="30"
                fontSize={10}
                fontWeight={"lighter"}
                stroke="rgb(109, 107, 222)"
              >
                more option
              </text>
            </Dropdown>
          )}
        </g>
      </Tooltip>
    );
  };

  // s x=0
  // s y=0

  // t x=-175
  // t y=140

  // M0,    0C70,     0,    70,  -175,  140, -175
  // M140, -175C210, -175,  210, -245,  280, -245
  const straightPathFunc = (linkDatum, orientation) => {
    const { source, target } = linkDatum;
    const truncatedName = source?.data?.name?.substring(0, 10);

    const tempSvg = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    const tempText = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );
    tempText.textContent = truncatedName;
    tempText.setAttribute("font-size", 20);
    tempSvg.appendChild(tempText);
    document.body.appendChild(tempSvg);

    const bbox = tempText.getBBox();

    const rectWidth = bbox.width;
    console.log("rectWidth: ", rectWidth, source?.data?.name);

    const sorceY = source.y + rectWidth + 30;

    const midY = (sorceY + target.y) / 2;
    if (orientation === "horizontal") {
      return `M${sorceY},${source.x}C${midY},${source.x},${midY},${target.x},${target.y},${target.x}`;
    } else {
      const midX = (source.x + target.x) / 2;
      return `M${source.y},${source.x}C${midY},${source.x},${midY},${target.x},${target.y},${target.x}`;
    }
  };

  return (
    <>
      <div className="structure_area">
        <div className="agent_head d_flx">
          <h2>Customise Frequently Ask Questions</h2>
        </div>
        <button
          className="fas fa-search-plus"
          type="button"
          style={{
            cursor: "pointer",
            border: "none",
            backgroundColor: "#414de1",
            color: "#f0f0f0",
            marginRight: "10px",
            padding: "5px",
            borderRadius: "5px",
            fontSize: "14px",
          }}
          onClick={handleZoomIn}
        />
        <button
          className="fas fa-search-minus"
          type="button"
          style={{
            cursor: "pointer",
            border: "none",
            backgroundColor: "#414de1",
            color: "#f0f0f0",
            marginRight: "10px",
            padding: "5px",
            borderRadius: "5px",
            fontSize: "14px",
          }}
          disabled={zoomLevel === 1}
          onClick={handleZoomOut}
        />
        <button
          className="fas fa-redo"
          type="button"
          style={{
            cursor: "pointer",
            border: "none",
            backgroundColor: "#414de1",
            color: "#f0f0f0",
            marginRight: "10px",
            padding: "5px",
            borderRadius: "5px",
            fontSize: "14px",
          }}
          onClick={reset}
        />
        <div className="structure_flow">
          {/* <figure>
            <img src="./images/structure.jpg" alt="image" />
          </figure> */}
          <div
            style={{
              transform: `translate(${position.x}px, ${position.y}px) scale(${zoomLevel})`,
            }}
            id="treeWrapper"
          >
            {data.length !== 0 && (
              <Tree
                data={data?.name ? data : treeData}
                // data={treeData}
                translate={{ x: 200, y: 330 }}
                zoomable={true}
                collapsible={false}
                renderCustomNodeElement={renderCustomNodeElement}
                pathFunc={straightPathFunc}
                svgClassName="treeWrapper"
              />
            )}
          </div>
          <AddMessage
            open={open}
            setOpen={setOpen}
            nodeId={nodeId}
            nodeName={nodeName}
          />
        </div>
      </div>
    </>
  );
};

export default FlowStructure;
