/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import Card from "./features/card";
import Satisfaction from "./features/satisfaction";
import NewAgent from "./features/newagent";
import { ActiveChat } from "./features/activechat";
import { ChatBot } from "./features/chatbot";
import { ResponseTime } from "./features/responsetime";

const Dashboard = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          <Card />
          <div className="d_flx">
            <ChatBot />
            <ActiveChat />
            <ResponseTime />
            <Satisfaction />
          </div>
          <NewAgent />
        </section>
      </Layout>
    </>
  );
};
export default Dashboard;
