import React from "react";
import Layout from "../../layout";
import FormLogin from "./features/form";

function Login() {
  return (
    <Layout>
        <FormLogin />
    </Layout>
  );
}

export default Login;
