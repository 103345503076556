import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useBotHandleConvsnMutation } from "../../../services/createbot";
import { successToast } from "../../../helpers";

const SuccessInfo = () => {
  const [handleBotConvrsn] = useBotHandleConvsnMutation();
  const [load, setLoad] = useState(false);
  let history = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data } = state;

  async function handleConversation() {
    try {
      setLoad(true);
      let body = {
        bot_handle_conversation: true,
      };
      const id = data?.id;
      const response = await handleBotConvrsn({ body, id });
      console.log("response: ", response);
      if (response?.data?.code === 200) {
        successToast(response?.data?.message);
        history("/managebot");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }

  const Flow = async () => {
    try {
      setLoad(true);
      let body = {
        bot_handle_conversation: false,
      };
      const id = data?.id;
      const response = await handleBotConvrsn({ body, id });
      console.log("response: ", response);
      if (response?.data?.code === 200) {
        successToast(response?.data?.message);
        history("/managebot");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <Spin spinning={load}>
        <div className="created_simi">
          <figure>
            <img src="/images/checked.png" alt="checked" />
          </figure>
          <h2>Awesome! You have created {data?.bot_name}</h2>
          <h3>
            Note: Please try '{data?.bot_name}' in a fresh conversation thread.
            It will not reply to old conversations that were created before
            integrating '{data?.bot_name}' .
          </h3>
          <p>
            You currently have a different bot, AI, handling all new
            conversations. Do you want to let this bot handle them instead?
          </p>
          <div className="btn_grp">
            <button type="button" className="cancel_btn" onClick={Flow}>
              I’ll set it up later
            </button>
            <button
              type="button"
              className="save_btn"
              onClick={handleConversation}
            >
              Let this bot handle all conversation
            </button>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default SuccessInfo;
