/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
// import BotSidebar from "../../components/botsidebar";
import BotList from "./features/botlist";
const ManageBot = () => {
  return (
    <>
      <Layout>
        <section className="integration_main">
          {/* <BotSidebar /> */}
          <BotList />
        </section>
      </Layout>
    </>
  );
};
export default ManageBot;
