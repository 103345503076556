/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import SettingSidebar from "../../components/settingsidebar";
import SubscriptionList from "./features/subscriptionlist";

const Subscription = () => {
  return (
    <>
      <Layout>
        <section className="setting_page d_flx align_top">
          <SettingSidebar />
          <SubscriptionList />
        </section>
      </Layout>
    </>
  );
};
export default Subscription;