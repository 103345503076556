/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { AgntPieChart } from "./piechart";

const ChatStatus = () => {
  return (
    <div className="shd_bx chat_bot">
      <h3>Chat Status</h3>
      <AgntPieChart />
    </div>
  );
};
export default ChatStatus;
