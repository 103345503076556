/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";

const { confirm } = Modal;
const showConfirm = (handleConfirm) => {
  confirm({
    // title: `Are you sure assign ${agntName} to this ${custName}?`,
    title: `Click ok to confirm`,
    icon: <ExclamationCircleFilled />,
    okText: "Ok",
    cancelText: "Cancel",
    onOk() {
      console.log("OK");
      handleConfirm();
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
export default showConfirm;
