export const isNumber = (value) => {
  if (!value.length) {
    return true;
  }
  return (
    !isNaN(parseFloat(value[value.length - 1])) &&
    isFinite(parseInt(value[value.length - 1]))
  );
};

export const isAlphabete = (input) => {
  const alphabeticPattern = /^[a-zA-Z\s]*$/;
  return alphabeticPattern.test(input);
};
export const isPasswordPattern = (password) => {
  const reg =
    /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  return reg.test(password);
};
export const matchPassword = (newpassword, confirmPassword) => {
  if (newpassword === confirmPassword) {
    return true;
  }
  return false;
};

export const isEmail = (email) => {
  let reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const validOTP = (otp) => {
  if (otp.length < 4) {
    return false;
  }
  return true;
};

export const validMobileNo = (number) => {
  let phoneNo = /^[1-9]{1}\d{9}$/;
  if (number) {
    if (phoneNo.test(number)) {
      return true;
    }
    return false;
  }
};
