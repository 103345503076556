/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Empty, Skeleton } from "antd";
import { timesAgo } from "../../../utils/timeago";
import { useConvsnHistMutation } from "../../../services/agentdashboard";

const ConversationHistory = () => {
  const [convsnHistory] = useConvsnHistMutation();
  const [convrsHis, setConvrsHis] = useState({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        let body = {
          page: 1,
          length: 10,
          search_value: "",
        };
        const response = await convsnHistory(body);
        console.log("response: ", response.data.data);
        setConvrsHis(response.data.data);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, [convsnHistory]);

  return (
    <div className="shd_bx chat_bot tableBox">
      <h3>Conversation History</h3>
      <div className="table_box">
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Conversation Status</th>
              <th>Last Seen</th>
            </tr>
          </thead>
          <tbody>
            <Skeleton loading={load} active block={true}>
              {convrsHis?.length ? (
                convrsHis?.map((conHis) => (
                  <tr key={conHis?.id}>
                    <td>{conHis.user_name}</td>
                    <td>{conHis?.conversation_status}</td>
                    <td>{timesAgo(conHis?.last_seen)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    {" "}
                    <Empty />
                  </td>
                </tr>
              )}
            </Skeleton>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ConversationHistory;
