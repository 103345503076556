import "./App.css";
import "./style.scss";
import "./style2.scss";
import Router from "./Routes";
import React, { useCallback, useEffect } from "react";
import { firebaseCloudMessaging } from "./utils/firebase";
import { toast } from "react-toastify";
import { getData } from "./constants";
import { STORAGE_KEYS, storeData } from "./constants/storage";
import { useDispatch } from "react-redux";
import { useGetUserDetailsMutation } from "./services/api";
import { setCredentials } from "./reducers/authSlice";

function App() {
  const dispatch = useDispatch();
  const [getProfileMethod] = useGetUserDetailsMutation();

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token::::::::::::::", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setToken();

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div>{event?.data?.notification?.title}</div>
              <div>{event?.data?.notification?.body}</div>
            </div>
          );
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  const getProfileData = useCallback(
    async (token) => {
      try {
        if (!token) {
          return;
        }
        const res = await getProfileMethod();
        storeData(STORAGE_KEYS?.userData, res?.data?.data);
        dispatch(
          setCredentials({
            user: res?.data?.data || null,
            token: token || null,
          })
        );
      } catch (error) {
        console.error("Error getting profile data:", error);
      }
    },
    [dispatch, getProfileMethod]
  );

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = getData(STORAGE_KEYS.token);
        const data = getData(STORAGE_KEYS.userData);
        if (token && data) {
          dispatch(
            setCredentials({
              user: data,
              token: token,
            })
          );
        } else {
          await getProfileData(token);
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    };

    checkToken();
  }, [dispatch, getProfileData]);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
