import Email from "../../../components/email";
import Password from "../../../components/password";
import UserInput from "../../../components/userinput";
import LoginButton from "../../../components/loginbutton";
import GoogleButton from "../../../components/googlebutton";
import FormHead from "../../../components/formhead";
import VerifyModal from "../../../components/modal";
import { useSignUpMutation } from "../../../services/api";
import PasswordCnf from "../../../components/passwordCnf";
import { signInWithPopup } from "firebase/auth";
import React, { useState } from "react";
import { auth, provider } from "../../../utils/firebaseKeys";
import { Link, useNavigate } from "react-router-dom";
import {
  isAlphabete,
  isEmail,
  isPasswordPattern,
  matchPassword,
} from "../../../utils/validations";
import { errorToast, successToast } from "../../../helpers/showToast";
import { Spin } from "antd";

const FormSignup = () => {
  let history = useNavigate();
  const [open, setOpen] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [bname, setBname] = useState("");
  const [pass, setPass] = useState("");
  const [cfmPass, setCfmPass] = useState("");
  const [signUp] = useSignUpMutation();
  const [require, setRequire] = useState(false);
  const [bdy, setBdy] = useState();
  const [load, setLoad] = useState(false);

  const onFinish = async () => {
    if (
      !fname ||
      !lname ||
      !email ||
      !bname ||
      !pass ||
      !cfmPass ||
      !isEmail(email) ||
      !matchPassword(pass, cfmPass) ||
      !isPasswordPattern(pass)
    ) {
      setRequire(true);
      return;
    }
    let body = {
      email: email,
      firstname: fname,
      lastname: lname,
      password: cfmPass,
      business_name: bname,
    };
    setBdy(body);
    let response;
    try {
      setLoad(true);
      response = await signUp(body);
      console.log("response: ", response);
      if (response?.data?.code === 200) {
        successToast(response?.data?.message);
        setOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
      errorToast(response?.error?.data?.message);
    } finally {
      setLoad(false);
    }
  };

  const handleFname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setFname(input);
    }
  };

  const handleLname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setLname(input);
    }
  };

  const loginWithGoogle = async () => {
    await signInWithPopup(auth, provider);
    history("/dashboard");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onFinish();
    }
  };

  return (
    <>
      <Spin spinning={load}>
        <section className="login_page">
          <div className="login_bx">
            <div className="lg_frm">
              <FormHead
                title=" Live chat support powered by
               AI chatbots automates customer conversations!"
              />
              <div className="d_flx flx_input">
                <UserInput
                  placeholder="First Name"
                  icon="/images/user.svg"
                  value={fname}
                  onChange={handleFname}
                  onKeyDown={handleKeyPress}
                  require={require}
                />
                <UserInput
                  placeholder="Last Name"
                  icon="/images/user.svg"
                  value={lname}
                  onChange={handleLname}
                  onKeyDown={handleKeyPress}
                  require={require}
                />
              </div>
              <UserInput
                placeholder="Business Name"
                icon="/images/business.svg"
                value={bname}
                onChange={(e) => setBname(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <Email
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <Password
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <PasswordCnf
                placeholder="Confirm Password"
                value={cfmPass}
                cfmPass={pass}
                onChange={(e) => setCfmPass(e.target.value)}
                require={require}
                onKeyDown={handleKeyPress}
              />
              <LoginButton name="Sign up for Free" click={onFinish} />
              <GoogleButton name="SignUp" onClick={loginWithGoogle} />
              <div className="an_aacount">
                <p>
                  Already have an account ?<Link to="/login">Sign In</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
        <VerifyModal
          setOpen={setOpen}
          open={open}
          email={email}
          data={"signup"}
          bdy={bdy}
        />
      </Spin>
    </>
  );
};

export default FormSignup;
