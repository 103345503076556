import { useLazyDashCardDataQuery } from "../../services/dashboard";
import {
  Link,
  useNavigate
} from "react-router-dom";

const Sidebar = (props) => {

  const history = useNavigate();
  const [cardData] = useLazyDashCardDataQuery();

  const fetchData = async () => {
    try {
      const response = await cardData();
      return response.data.data.existing_chatbot;
    } catch (error) {
      console.log('error: ', error);
      return null;
    }
  };

  const handleLinkClick = async () => {
    const chatbot = await fetchData();
    if (chatbot) {
      history('/dashboard');
    } else {
      history('/createbot');
    }
  };

  return (
    <>
      <div className={props.activeCls ? "sidebar active" : "sidebar"}>
        <figure className="logo_sidebar">
          <img src="/images/logo_sidebar.svg" alt="logo"></img>
        </figure>
        <ul><li
          className={
            window.location.pathname === "/dashboard" || window.location.pathname === "/createbot" ? "active" : ""
          }
        >
          <Link onClick={handleLinkClick} >
            <figure>
              <img src="/images/dashboard.svg" alt="logo"></img>
            </figure>
            <span>Dashboard</span>
          </Link>
        </li>
          <li
            className={
              window.location.pathname === "/conversation" ? "active" : ""
            }
          >
            <Link to="/conversation">
              <figure>
                <img src="/images/customer-service.svg" alt="logo"></img>
              </figure>
              <span>Conversation</span>
            </Link>
          </li>
          <li
            className={
              window.location.pathname === "/botintegration" ||
                window.location.pathname === "/bot_template" ||
                window.location.pathname === "/botprofile" ||
                window.location.pathname === "/handoff" ||
                window.location.pathname === "/success" ||
                window.location.pathname === "/defaultflow" ||
                window.location.pathname === "/domainsetup" ||
                window.location.pathname === "/training" 
                ? "active"
                : ""
            }
          >
            <Link to="/botintegration">
              <figure>
                <img src="/images/robot.svg" alt="logo"></img>
              </figure>
              <span>Create New Bot</span>
            </Link>
          </li>
          <li
            className={window.location.pathname === "/managebot" ? "active" : ""}
          >
            <Link to="/managebot">
              <figure>
                <img src="/images/manage_bot.svg" alt="logo"></img>
              </figure>
              <span>Manage Bot</span>
            </Link>
          </li>
          <li
            className={window.location.pathname === "/customer" ? "active" : ""}
          >
            <Link to="/customer">
              <figure>
                <img src="/images/user_n.svg" alt="logo"></img>
              </figure>
              <span>Manage Customer</span>
            </Link>
          </li>
          <li
            className={
              window.location.pathname === "/employees" ||
                window.location.pathname === "/addagent" ||
                window.location.pathname === "/agentview"
                ? "active"
                : ""
            }
          >
            <Link to="/employees">
              <figure>
                <img src="/images/people.svg" alt="logo"></img>
              </figure>
              <span>Manage Agent</span>
            </Link>
          </li>
          <li
            className={window.location.pathname === "/rating" ? "active" : ""}
          >
            <Link to="/rating">
              <figure>
                <img src="/images/rating.svg" alt="logo"></img>
              </figure>
              <span>Manage Ratings</span>
            </Link>
          </li>
          <li
            className={
              window.location.pathname === "/thememanagement" ||
                window.location.pathname === "/apikey" ||
                window.location.pathname === "/subscription" ||
                window.location.pathname === "/servey" ||
                window.location.pathname === "/agentavailability" ||
                window.location.pathname === "/fallbackemail" ||
                window.location.pathname === "/pushnotification"
                ? "active"
                : ""
            }
          >
            <Link to="/thememanagement">
              <figure>
                <img src="/images/settings.svg" alt="logo"></img>
              </figure>
              <span>Settings</span>
            </Link>
          </li></ul>
      </div>
    </>
  );
};
export default Sidebar;
