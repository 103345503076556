/* eslint-disable jsx-a11y/img-redundant-alt */
import { useDashBrdNewAgentMutation } from "../../../services/dashboard";
import React, {
  useEffect,
  useState
} from "react";
import {
  Empty,
  Spin
} from "antd";

const NewAgent = () => {

  const [newAgent] = useDashBrdNewAgentMutation();
  const [agentData, setAgentData] = useState()

  const [load, setLoad] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        let body = {
          page: 1,
          length: 10,
          search_value: ""
        }
        setLoad(true)
        const response = await newAgent(body);
        console.log('response: ', response?.data?.data);
        setAgentData(response?.data?.data)
      } catch (error) {
        console.log('error: ', error);
      } finally {
        setLoad(false)
      }
    }
    fetchData();
  }, [newAgent])

  const handleDate = (date) => {
    const createdAt = date;
    const dateObject = new Date(createdAt);
    const formattedDate = dateObject.toLocaleDateString();
    return formattedDate
  }

  return (
    <div className="shd_bx w_100 chat_bot">
      <h3>New Agent</h3>
      <p>Lorem ipsum dolor sit amet</p>

      <div className="table_box dash_tbl">
        <Spin spinning={load} size="medium">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {agentData?.length ? (agentData?.map((agent) => (
                <tr key={agent?.id}>
                  <td>{agent?.first_name + " " + agent?.last_name}</td>
                  <td>{agent?.email}</td>
                  <td>{agent?.phone_no}</td>
                  <td>{handleDate(agent?.created_at)}</td>
                </tr>
              ))) : <tr><td colSpan={4}><Empty /></td></tr>}
            </tbody>
          </table>
        </Spin>
      </div>
    </div>
  );
};
export default NewAgent;
