/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
const { confirm } = Modal;
const DeleteModal = (handleDelete, block, active) => {
  confirm({
    title: `Do you really want to ${
      block ? (active ? "block" : "unblock") : "delete"
    } this ?`,
    icon: <ExclamationCircleFilled />,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      console.log("OK");
      handleDelete();
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
export default DeleteModal;
