import React from 'react';
import { Pagination } from 'antd';

const PaginationBox = ({
  defaultCurrent,
  current,
  onChange,
  total
}) => {
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a >Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  return (
    <div className="pagi_nation">
      <div style={{ backgroundColor: '#f9f9f9', padding: 10, borderRadius: "10px" }}>
        <Pagination
          itemRender={itemRender}
          defaultCurrent={defaultCurrent}
          current={current}
          onChange={onChange}
          total={total} />
      </div>
    </div>
  );
};

export default PaginationBox;
