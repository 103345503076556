import React from "react";

export default function LiveChatView() {
  return (
    <div className="main_lvcht">
      <div className="live_chat_bx">
        <div className="chatview_head">
          <figure>
            <img src="../images/bot.png" alt="bot" />
          </figure>
          <div>
            <h2>pop0</h2>
            <p>Online</p>
          </div>
        </div>

        <div className="chatview">
          <div className="lhs_chat">
            <span>hi bot</span>
          </div>
          <div className="rhs_chat">
            <span>hi bot d df df df df df df df d</span>
            <span>hi bot</span>
          </div>
        </div>
        <div className="prw_info">
          <p>
            You can only see preview here. To test your bot, please train bot
            and click the button below
          </p>
        </div>
      </div>
      <div className="tst_ing_grp">
        <button type="button" className="border_btn">Test popo </button>
        <button type="button" className="save_btn">Integrate</button>
      </div>
    </div>
  );
}
