import React, { useEffect, useRef, useState } from "react";
import { Empty, Spin } from "antd";
import {
  useAssignToAgntMutation,
  useLazyGetagentListQuery,
  useLazyPreviousChatQuery,
  useReAssignAgentMutation,
} from "../../../services/conversation";
import { errorToast, successToast } from "../../../helpers/showToast";
import formatTime from "../../../utils/timeago";
import showConfirm from "../../../components/confirm";
import "react-tabs/style/react-tabs.css";

const ConversationPanel = ({
  idFromLeftPanel,
  nameFromLeftPanel,
  tabSlt,
  sendAssignResponse,
}) => {
  const [agentList] = useLazyGetagentListQuery();
  const [previousChat] = useLazyPreviousChatQuery();
  const [asignToAgnt] = useAssignToAgntMutation();
  const [reAssignAgent] = useReAssignAgentMutation();
  const [agtList, setAgtList] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [load, setLoad] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    setChatData([]);
  }, [tabSlt]);

  const handleAssign = async (id) => {
    console.log("id:======= ", id);
    if (tabSlt === 0 || !tabSlt) {
      let response;
      try {
        setLoad(true);
        response = await asignToAgnt({
          body: { agent_id: id },
          seId: idFromLeftPanel,
        });
        console.log("response: ", response);
        successToast(response?.data?.message);
        sendAssignResponse("assigned");
        setChatData([]);
      } catch (error) {
        console.error("Error assigning to agent:", error);
        errorToast(response?.error?.data?.message);
      } finally {
        setLoad(false);
      }
    } else if (tabSlt === 3) {
      let response;
      try {
        setLoad(true);
        response = await reAssignAgent({
          body: { agent_id: id },
          seId: idFromLeftPanel,
        });
        console.log("response:reassinged ", response);
        successToast(response?.data?.message);
        sendAssignResponse("reAssigned");
        setChatData([]);
      } catch (error) {
        console.error("Error assigning to agent:", error);
      } finally {
        setLoad(false);
      }
    }
  };

  useEffect(() => {
    const fetchChat = async () => {
      if (idFromLeftPanel) {
        try {
          setLoad(true);
          const response = await previousChat(idFromLeftPanel);
          const responseData = response?.data?.data;
          console.log("responseData: ", responseData);
          setChatData(responseData);
        } catch (error) {
          console.error("Error fetching chat:", error);
        } finally {
          setLoad(false);
        }
      }
    };
    fetchChat();
  }, [idFromLeftPanel, previousChat, agentList, tabSlt]);

  useEffect(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatData]);

  const handleChat = (chat) => {
    switch (chat?.user_input?.type) {
      case "option_type":
        return (
          <>
            {chat?.user_input?.Bot?.welcome_message && (
              <p className="welcome_msg">
                {chat?.user_input?.Bot?.welcome_message}
              </p>
            )}
            <div className="options_box">
              {chat?.user_input?.Bot?.options?.map((opt) => (
                <p>{opt?.name}</p>
              ))}
            </div>
          </>
        );
      case "text_type":
        return <p>{chat?.user_input?.message?.name}</p>;
      case "message_type":
        return <p>{chat?.user_input?.message?.name}</p>;
      case "wait_type":
        return <p className="welcome_msg">{chat?.user_input?.message?.name}</p>;
      case "resolved_type":
        return <p className="welcome_msg">{chat?.user_input?.message?.name}</p>;
      case "chat_ended":
        return (
          <>
            <p className="welcome_msg">
              {chat?.user_input?.Bot?.welcome_message}
            </p>
            <div className="options_box">
              {chat?.user_input?.Bot?.options?.map((opt, index) => (
                <p key={index}>{opt?.name}</p>
              ))}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const fetchAgentList = async () => {
    try {
      const response = await agentList();
      console.log("response:::::::::::: ", response);
      if (response?.error?.data?.code === 400) {
        setAgtList([]);
      } else {
        const responseData = response?.data?.data;
        setAgtList(responseData);
      }
    } catch (error) {
      console.error("Error fetching agent list:", error);
    }
  };

  return (
    <div className="conv_rightPanel">
      <div className="agent_head assign_head">
        <h2>Conversation</h2>
      </div>
      {chatData?.length ? (
        <Spin spinning={load} size="medium">
          {tabSlt === 0 || tabSlt === 3 || !tabSlt ? (
            <div className="asign_box" onClick={fetchAgentList}>
              <label>Assign:</label>
              <select
                name="box"
                id="bd"
                onChange={(e) =>
                  showConfirm(() => handleAssign(e.target.value))
                }
              >
                <option value="">Select Agent</option>
                {agtList?.length ? (
                  agtList?.map((agent) => (
                    <option value={agent?.id} key={agent?.id}>
                      {`${agent?.first_name} ${agent?.last_name}`}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    Agent Not Available
                  </option>
                )}
              </select>
            </div>
          ) : null}
          <div className="chat_box" ref={chatContainerRef}>
            {chatData?.map((chat) =>
              chat?.user_input?.type === "agent_assigned" ? (
                <>
                  <div className="assigned_line">
                    <p>{chat?.user_input?.message?.name}</p>
                  </div>
                </>
              ) : (
                <div className="cobersation_chat">
                  <div className="alex_box">
                    <figure>
                      <img
                        src={
                          chat?.profile_pic
                            ? chat?.profile_pic
                            : "/images/frame.png"
                        }
                        alt="alex"
                      />
                    </figure>
                    <div>
                      <h3>{chat?.user_name}</h3>
                      {handleChat(chat)}
                      <span>{formatTime(chat?.timestamp)}</span>
                    </div>
                  </div>
                </div>
              )
            )}
            {tabSlt !== 0 && tabSlt ? (
              <div className="assign_line">
                <h4>Assign to {nameFromLeftPanel}</h4>
              </div>
            ) : null}
          </div>
        </Spin>
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          description="No Chats Available"
        />
      )}
    </div>
  );
};

export default ConversationPanel;
