import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const dashboardApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        dashCardData: builder.query({
            query: () => ({
                url: END_POINTS.getCardData,
                method: 'GET',
            }),
        }),
        dashBrdNewAgent: builder.mutation({
            query: (body) => ({
                url: END_POINTS.getNewAgent,
                method: 'POST',
                body
            }),
        }),
        avtiveChat: builder.query({
            query: () => ({
                url: END_POINTS.activeChat,
                method: 'GET',
            }),
        }),
        headerNotification: builder.mutation({
            query: () => ({
                url: END_POINTS.headerNotification,
                method: 'POST',
            }),
        }),
        getTestMode: builder.query({
            query: () => ({
                url: END_POINTS.getTestMode,
                method: 'GET',
            })
        }),
        PutTestMode: builder.mutation({
            query: (body) => ({
                url: END_POINTS.PutTestMode,
                method: 'PUT',
                body,
            })
        }),
        satisfiedRating: builder.query({
            query: (id) => ({
                url: `${END_POINTS.satisfiedRating}${id}/`,
                method: 'GET',
            }),
        }),
        avgTalkToHuman: builder.query({
            query: () => ({
                url: END_POINTS?.avgTalkToHuman,
                method: 'GET',
            }),
        }),
        avgResponseTime: builder.query({
            query: () => ({
                url: END_POINTS?.avgResponseTime,
                method: 'GET',
            }),
        })
    }),
});

export const {
    useLazyDashCardDataQuery,
    useDashBrdNewAgentMutation,
    useLazyAvtiveChatQuery,
    useHeaderNotificationMutation,
    useLazyGetTestModeQuery,
    usePutTestModeMutation,
    useLazySatisfiedRatingQuery,
    useLazyAvgTalkToHumanQuery,
    useLazyAvgResponseTimeQuery,
} = dashboardApi;
