/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import {
  useLazyThemeByIdQuery,
  useUpdateCsatMutation,
} from "../../../services/settings";
import { successToast } from "../../../helpers";
import useAuth from "../../../hooks/useAuth";

const CstaRating = () => {
  const [getCsat] = useLazyThemeByIdQuery();
  const [saveCsat] = useUpdateCsatMutation();
  const [csat, setCsat] = useState(0);
  const userData = useAuth();

  useEffect(() => {
    const fetchCsatData = async () => {
      try {
        const response = await getCsat(userData?.business_name?.bussiness_id);
        const responseData = response?.data?.data;
        console.log("response: ", responseData);
        setCsat(responseData?.c_sat);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchCsatData();
  }, [getCsat, userData?.business_name?.bussiness_id]);

  const saveData = async () => {
    const companyId = await userData?.business_name?.bussiness_id;
    const valueCsat = !csat;
    try {
      let body = {
        c_sat: valueCsat,
      };
      const response = await saveCsat({ body, id: companyId });
      successToast(response?.data?.message);
      console.log("response: ", response);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      <div className="stng_rhs">
        <div className="agent_head">
          <h2>CSAT Ratings</h2>
          <p>Measure how satisfied your users are with your customer support</p>
          <hr />
        </div>
        <div className="csat_toggle d_flx">
          <h3>Ask your users for CSAT ratings</h3>
          <label className="switch">
            <input
              type="checkbox"
              checked={csat}
              onChange={(e) => setCsat(e.target.checked)}
              onClick={saveData}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="gr_para">
          <p>
            Your users will be asked to rate their support experience and leave
            comments after you or your team members resolve the conversation.
          </p>
        </div>
      </div>
    </>
  );
};
export default CstaRating;
