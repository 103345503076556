import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const agntConvrsn = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    agntAssignList: builder.mutation({
      query: (body) => ({
        url: END_POINTS.agntAssign,
        method: "POST",
        body,
      }),
    }),
    agntResolveList: builder.mutation({
      query: (body) => ({
        url: END_POINTS.agntResolve,
        method: "POST",
        body,
      }),
    }),
    allChats: builder.mutation({
      query: (body) => ({
        url: END_POINTS.allChats,
        method: "POST",
        body,
      }),
    }),
    customerFilter: builder.mutation({
      query: (body) => ({
        url: END_POINTS.customerFilter,
        method: "POST",
        body,
      }),
    }),
    markAsUnresolved: builder.query({
      query: (id) => ({
        url: `${END_POINTS.markAsUnresolved}${id}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAgntAssignListMutation,
  useAgntResolveListMutation,
  useAllChatsMutation,
  useCustomerFilterMutation,
  useLazyMarkAsUnresolvedQuery,
} = agntConvrsn;
