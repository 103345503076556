/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { useLazyAgntDashboardQuery } from "../../../services/agentdashboard";

const TopCards = () => {
  const [cardData] = useLazyAgntDashboardQuery();
  const [totalChat, setTotalChat] = useState();
  const [cmltChat, setCmltChat] = useState();
  const [activeChat, setActiveChat] = useState();
  const [pendingChat, setPendingChat] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await cardData();
        const response = res.data.data;
        setCmltChat(response.complete_chat);
        setTotalChat(response.total_chat);
        setActiveChat(response.active_chat);
        setPendingChat(response.pending_chat);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchData();
  }, [cardData]);

  return (
    <div className="top_cards">
      <div className="shd_bx card_bx d_flx">
        <figure>
          <img src="/images/communication.svg" alt="image"></img>
        </figure>
        <div>
          <h2>{totalChat ?? "0"}</h2>
          <p>Total Chat</p>
        </div>
      </div>
      <div className="shd_bx card_bx d_flx">
        <figure>
          <img src="/images/email_n.svg" alt="image"></img>
          <img className="overlayTag" src="/images/orange-icon.png" alt="" />
        </figure>
        <div>
          <h2>{pendingChat ?? "0"}</h2>
          <p>Pending Chat</p>
        </div>
      </div>
      <div className="shd_bx card_bx d_flx">
        <figure>
          <img src="/images/email_n.svg" alt="image"></img>
          <img className="overlayTag" src="/images/green-icon.png" alt="" />
        </figure>
        <div>
          <h2>{cmltChat ?? "0"}</h2>
          <p>Completed Chat</p>
        </div>
      </div>
      <div className="shd_bx card_bx d_flx">
        <figure>
          <img src="/images/email_n.svg" alt="image"></img>
          <img className="overlayTag" src="/images/blue-icon.png" alt="" />
        </figure>
        <div>
          <h2>{activeChat ?? "0"}</h2>
          <p>Active Chat</p>
        </div>
      </div>
    </div>
  );
};
export default TopCards;
